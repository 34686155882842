import React, { useContext, useEffect, useState } from "react";
import apis from "../../api";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import SupportDataCard from './components/Details/SupportDetails'
import ticketStyles from './ticketStyles.module.css'
import ChatPageCard from './components/Chatpage/Chatpage'
const ts  = ticketStyles 
export default function Ticket() {
  const params = useParams();
  const auth = useContext(AuthContext);
  const [supportData, setSupportData] = useState([]);
  const handleSupportData = async () => {
    const res = await apis.GetSupportDetails(
      auth?.user?.attributes?.sub,
      params?.supportId
    );
    console.log(res);
    setSupportData(res);
  };
  useEffect(() => {
    handleSupportData();
  }, []);

  return (
    <>
    <div className={ts.main_con}>
    <div className={ts.account_card}>
      <SupportDataCard chatData={supportData[0]}/>
    </div>
    <div className={ts.chat_card}>
      <ChatPageCard/>
    </div>
    </div>
    </>
      )
  }