import React, { useContext, useState } from "react";
import { Button } from "../../components";
import ProjectStyle from "./projectStyle.module.css";
import apis from "../../api";
import { NewProject, OnGoing, Completed } from "./index";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import { Loader } from "../../components/Loader/Loader";

const ps = ProjectStyle;

function Projects() {
  const auth = useContext(AuthContext)
  const { userInfo } = useContext(AuthContext);
  console.log(userInfo, ">>>>>>>>>>>>>>>>>>>>>>>>>>");
  const [newProjectTable, setNewProjectTable] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const handleNewProject = async () => {
    const res = await apis.GetnewProject(auth?.user?.attributes?.sub);
    setNewProjectTable(res);
    console.log(res);
  };

  const [projectCompleteDetails, setProjectCompleteDetails] = useState([]);

  const handleCompletedProjects = async () => {
    const res = await apis.GetCompletedProject(auth?.user?.attributes?.sub);
    setProjectCompleteDetails(res);
    console.log("completed", res);
  };

  const [projectOngoingDetails, setProjectOngoingDetails] = useState([]);

  const handleOngoingProjects = async () => {
    const res = await apis.GetOnGoing(auth?.user?.attributes?.sub);
    setProjectOngoingDetails(res);
    console.log("Ongoingd", res);
  };

  React.useEffect(() => {
    handleNewProject();
    handleCompletedProjects();
    handleOngoingProjects();
    if (newProjectTable) {
      setIsLoading(false);
    }
  }, []);

  const [selectStatus, setSelectStatus] = useState("NewProject");
  const [projectManager, setProjectManager] = useState("NewProject");

  const projectView = () => {
    switch (projectManager) {
      case "NewProject":
        return <NewProject newProjectList={newProjectTable} />;
      case "OnGoing":
        return <OnGoing onGoingProjectList={projectOngoingDetails} />;
      case "Completed":
        return <Completed completedProjectList={projectCompleteDetails} />;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className={ps.user_title}>Hello {userInfo?.firstname}!</div>
          <div className={ps.project_title}>
            <Button
              color={`${selectStatus === "NewProject" ? "color1" : "color3"}`}
              onClick={() => {
                setProjectManager("NewProject");
                setSelectStatus("NewProject");
              }}
            >
              New Project
            </Button>
            <Button
              color={`${selectStatus === "OnGoing" ? "color1" : "color3"}`}
              onClick={() => {
                setProjectManager("OnGoing");
                setSelectStatus("OnGoing");
              }}
            >
              OnGoing
            </Button>
            <Button
              color={`${selectStatus === "Completed" ? "color1" : "color3"}`}
              onClick={() => {
                setProjectManager("Completed");
                setSelectStatus("Completed");
              }}
            >
              Completed
            </Button>
          </div>
          <div>{projectView()}</div>
        </div>
      )}
    </>
  );
}

export default Projects;
