import React, { InputHTMLAttributes } from "react";
import { Eye, EyeOff } from "../../assets/index";
import FormInputStyles from "./forminputStyles.module.css";

const fis = FormInputStyles;

interface IFormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelstyle?: any;
  width?: string;
  backgroundColor?: string;
  sizePercentage?: string;
}

const FormInput = React.forwardRef<HTMLInputElement, IFormInputProps>(
  ({ label, id, ...props }, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleTogglePassword = () => {
      setShowPassword(!showPassword);
    };
    const inputBoxStyle = {
      width: `${props?.width}%`, // Set the width based on the prop value
      backgroundColor: `${props?.backgroundColor}`
    };


    return (
      <div className={fis.input_con}>
        {label && (
          <label style={props?.labelstyle} htmlFor={id}>
            {label}
          </label>
        )}
        <input
          id={id}
          ref={ref}
          {...props}
          type={showPassword ? "text" : props?.type}
          style={inputBoxStyle}
          className={fis.input}
        />
        {props.type === "password" && (
          //   <div onClick={handleTogglePassword} >
          <img
            src={showPassword ? EyeOff : Eye}
            className={fis.show_password_icon}
            alt={props.alt}
            onClick={handleTogglePassword}
          />
          //   </div>
        )}
      </div>

    )
  }
);

export default FormInput;
