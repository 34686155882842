import React, { useState } from "react";
import sidebarStyles from "./sidebar.module.css";
import { ConsdroidLogo, LogOut } from "../../assets";
import { CadWebLogo } from "../../assets";

import { useAuthContext } from "../../hooks";
import SidebarModule from "../Sidebar/components/SidebarLink/SidebarLink";
import SidebarData from "../Sidebar/sidebarData";
import Button from "../Buttons/Button";
const ss = sidebarStyles;

interface SidebarModuleProps {
  module: string;
  path: string;
  icon: string;
  activeModule?: string | null;
  handleModuleClick?: (module: string) => void;
}

const Sidebar = () => {
  const auth = useAuthContext();
  const [activeModule, setActiveModule] = useState("Dashboard");

  const handleModuleClick = (module: string) => {
    setActiveModule(module);
  };

  return (
    <div className={ss.main_con}>
      <div className={ss.links_con}>
        <div className={ss.brand_card}>
          <img src={CadWebLogo} alt="consdroid-logo" />
        </div>
        <div className={ss.links_main}>
          {SidebarData?.map((data: SidebarModuleProps) => (
            <SidebarModule
              key={data.module}
              module={data.module}
              path={data.path}
              icon={data.icon}
              handleModuleClick={handleModuleClick}
              activeModule={activeModule}
            />
          ))}
        </div>
      </div>
      <div className={ss.button}>
        <Button
          color="color1"
          icon={LogOut}
          iconPosition="left"
          onClick={() => {
            auth.signOut()
          }}
          width="narrow"
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
