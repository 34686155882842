import React, { useContext } from "react";
import SIDE_BAR_DATA from "../../Sidebar/sidebarData";
import MenuStyles from "./menuStyles.module.css";
import { Link } from "react-router-dom";
import { ConsdroidLogo, LogOut } from "../../../assets";
import Button from "../../Buttons/Button";
import { AuthContext } from "../../../context/AuthContext/AuthContext";
const mss = MenuStyles;
function Menu() {
  const auth = useContext(AuthContext);
  return (
    <div className={mss.main_con}>
      <div className={mss.img_con}>
        {" "}
        <img src={ConsdroidLogo} alt="consdroid logo" />
      </div>
      {SIDE_BAR_DATA?.map((data: any) => (
        <div className={mss.side_con}>
          <Link className={mss.link_style} to={data?.path}>
            <img src={data?.icon} alt={data?.module + "icon"} /> {data?.module}
          </Link>
        </div>
      ))}
      <div className={mss.button}>
        <Button
          color="color2"
          icon={LogOut}
          iconPosition="left"
          onClick={() => {
            auth.signOut();
          }}
          width="wide"
        >
          Logout
        </Button>
      </div>
    </div>
  );
}
export default Menu;
