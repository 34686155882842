import React from 'react'
import { Button } from '../../../../../components';
import { Link } from 'react-router-dom';
import ProjectListStyles from "./ProjectTableStyles.module.css";
const pls = ProjectListStyles;
interface ISiteDetails {
  site_name: string;
  budget_max: number;
  budget_min: number;
  build_area: string;
  floors: number;
  is_budget_estimation: boolean;
  is_threeD: boolean;
  is_twoD: boolean;
  site_area: number;
  site_breadth: number;
  site_key: string;
  site_length: number;
  site_purpose: string;
  site_type: string;
  siteplan_price_with_gst: number;
  ec_fileextension: string;
  ec_filename: string;
  ec_filepath: string;
  ec_filetype: string;
  ec_key: string;
  ec_survey_number: string;
}
interface INewProjectData {
  job_id: string;
  site_area: number;
  site_details: ISiteDetails;
}

function ProjectTable({ projectList }: any) {
  console.log(projectList)
  return (<>
    {projectList.length === 0 ?
      <div className={pls.custom_message}>**No New Projects Available **</div> :
      <table className="nps.table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Job ID</th>
            <th>Site Name</th>
            <th>Area</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {projectList?.map((data: INewProjectData, index: number) => {

            return (
              <tr>
                <td>{index + 1}</td>
                <td>
                  {data?.job_id.slice(0, 3)} {data?.job_id.slice(3, 7)}{" "}
                  {data?.job_id.slice(7, 11)} {data?.job_id.slice(11, 12)}{" "}
                </td>
                <td>{data?.site_details?.site_name}</td>
                <td>{data?.site_details?.site_area}sq</td>
                <td>
                  <div className={pls.projectBtn}>
                    <Link to={`projects/${data?.job_id}`}>
                      <Button width={"wide"} color={"color1"}>
                        View
                      </Button>
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>}
  </>
  )
}

export default ProjectTable