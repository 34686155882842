import { Accounts, Template, Support, Projects } from "../../assets";
const SIDE_BAR_DATA = [
  {
    module: "Projects",
    path: "/",
    icon: Projects,
  },
  {
    module: "Templates",
    path: "/template",
    icon: Template,
  },
  {
    module: "Accounts",
    path: "/accounts",
    icon: Accounts,
  },
  {
    module: "Support",
    path: "/support",
    icon: Support,
  },
];

export default SIDE_BAR_DATA;
