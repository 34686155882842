import React from "react";
import SupportDataCardStyles from './supportdatacardStyles.module.css'
import { Button } from "../../../components";
import { useNavigate } from "react-router";
const scs = SupportDataCardStyles;
interface Alltickets {
  Alltickets: never[];
}
function SupportDataCard({ Alltickets }: Alltickets) {
  const navigate = useNavigate();
  console.log(Alltickets)
  const handleChatData = ((id: number) => {
    navigate(`${id}`)
  })
  return (
    Alltickets && (Object.keys(Alltickets).length !== 0 ?
      <div className={scs.main_con}>
        {Alltickets?.map(
          ({ id, comment, reason_for_support, status, view }) => (
            <div className={scs.card}>
              <div className={scs.card_con}>
                <div className={scs.card_label}>Id :</div>
                <div className={scs.card_value}>{id}</div>
              </div>
              <div className={scs.card_con}>
                <div className={scs.card_label}>Reason For Support :</div>
                <div className={scs.card_value}>{reason_for_support}</div>
              </div>
              <div className={scs.card_con}>
                <div className={scs.card_label}>Comment:</div>
                <div className={scs.card_value}>{comment}</div>
              </div>

              <div className={scs.card_con}>
                <Button className={scs.button} width="wide" onClick={(() => { handleChatData(id) })}>view</Button>
              </div>
            </div>))}
      </div> : <div> you don't have any Tickets</div>)
  );
}

export default SupportDataCard;
