import React, { useContext, useState } from "react";
import EstimationStyles from "./estimationStyles.module.css";
import Folder from "../../../../assets/Folder.svg";
import Delete from "../../../../assets/delete.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalComponent } from "../../../../components";
import NewSection from "../UploadSection/UploadSection";
import DeleteModal from "../DeleteSection/DeleteModal";
import { AuthContext } from "../../../../context/AuthContext/AuthContext";

const es = EstimationStyles;

const Estimation = (props: any) => {
  const location = useLocation();
  const { userInfo } = useContext(AuthContext);
  const Navigate = useNavigate();
  const { id, key } = location.state || {};
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  //Here the section key is passed as props through navigation, so that you access the section in the fileUpload component.

  const handleClick = (section_key: any) => {
    Navigate(`section`, { state: { section_key } });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const [sectionKey, setSectionKey] = useState("");
  const [projectKey, setProjectKey] = useState("");
  const [userKey, setUserKey] = useState("");

  const OpenDeleteModal = (SectionKey: any, projectKey: any, UserKey: any) => {
    setSectionKey(SectionKey);
    setProjectKey(projectKey);
    setUserKey(UserKey);
    setShowDeleteModal(true);
  };
  return (
    <div>
      <div className={es.create_sec}>
        <div className={es.create_text} onClick={() => setShowModal(true)}>
          New Section
        </div>
      </div>

      <div className={es.card2}>
        {props.estimation_data?.map((item: any) => {
          console.log(item?.section_name);
          console.log(item?.section_key);
          return (
            <>
              <div className={es.card}>
                <div className={es.card1}>
                  <div
                    onClick={() => handleClick(item?.section_key)}
                    className={es.tempFolder}
                  >
                    <div className={es.foldername}>
                      <img src={Folder} alt="" />
                    </div>
                    <div className={es.foldername}>{item?.section_name}</div>
                  </div>
                  <div
                    onClick={() => {
                      OpenDeleteModal(
                        item?.section_key,
                        item?.project_key,
                        userInfo?.user_key
                      );
                    }}
                  >
                    <img src={Delete} alt="" />
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>

      {/* <div className={es.card}>
        {props.estimation_data?.map((item: any) => {
          console.log(item?.section_name);
          console.log(item?.section_key);
          return (
            <p
              className={es.foldername}
              onClick={() => {
                handleClick(item?.section_key);
              }}
            >
              <span>
                <img src={Folder} alt="" />
              </span>
              {item?.section_name}
            </p>
          );
        })}
      </div> */}
      <ModalComponent
        Component={() => (
          <NewSection
            design_template="estimation"
            close={handleClose}
            project_key={key}
            getfunction={props?.getfunction}
          />
        )}
        isOpen={showModal}
        onClose={handleClose}
        title="Create New Section"
      />
      <ModalComponent
        Component={() => (
          <DeleteModal
            section={sectionKey}
            project={projectKey}
            user={userKey}
          />
        )}
        isOpen={showDeleteModal}
        onClose={handleDeleteClose}
        title="Delete section"
      />
    </div>
  );
};

export default Estimation;
