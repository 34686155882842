import React, { useEffect, useState } from "react";
import ProjectCardStyle from "./ProjectCardStyle.module.css";
import { SitePlan } from "../../../../assets";
import {
  Button,
  DownloadContent,
  ModalComponent,
} from "../../../../components";
import { Link } from "react-router-dom";
import apis from "../../../../api";
import UploadModal from "./UploadModal";

const pcs = ProjectCardStyle;
interface ISiteDetails {
  site_name: string;
  budget_max: number;
  budget_min: number;
  build_area: string;
  floors: number;
  is_budget_estimation: boolean;
  is_threeD: boolean;
  is_twoD: boolean;
  site_area: number;
  site_breadth: number;
  site_key: string;
  site_length: number;
  site_purpose: string;
  site_type: string;
  siteplan_price_with_gst: number;
  ec_fileextension: string;
  ec_filename: string;
  ec_filepath: string;
  ec_filetype: string;
  ec_key: string;
  ec_survey_number: string;
  file_upload_status: string;
}
interface IProjectData {
  job_status: string;
  job_id: string;
  site_area: number;
  site_details: ISiteDetails;
}

function ProjectCard({ projectList }: any) {
  const [isDownloadModal, setIsDownloadModal] = useState(false);
  const handleClick = () => { };
  const handleClose = () => {
    setIsDownloadModal(false);
  };

  const [fileUploadStatus, setFileUploadstatus] = useState<any>(null);

  const handleFileUpload = async (data: any) => {
    try {
      // Replace the following line with the actual API implementation
      // Make sure it returns a Promise with the file data
      const res = await apis.GetJobDetails(data?.job_id);
      setFileUploadstatus(res);
      setIsDownloadModal(true);

      console.log("File data-------", res);
    } catch (error) {
      console.error("Error fetching file data:", error);
    }
  };
  console.log("Upload--------", (fileUploadStatus?.file_upload_status))

  return (
    <div className={pcs.cardMain}>
      <div className={pcs.projectCardMain}>
        {projectList.map((data: IProjectData, index: number) => (
          <div key={index} className={pcs.projectCardContainerCard}>
            <div className={pcs.projectCardContainer}>
              <div>
                <div className={pcs.containerDetails}>
                  <div style={{ fontWeight: "bold" }}>JOB ID</div>
                  <div>{data?.job_id}</div>
                </div>
                <div className={pcs.containerDetails}>
                  <div style={{ fontWeight: "bold" }}>Status</div>
                  <div>{data?.job_status}</div>
                </div>
                <div>
                  <img src={SitePlan} alt="siteplan" />
                </div>
              </div>
              <div className={pcs.siteplanContainer}>
                <div className={pcs.containerPlan}>
                  <div className={pcs.containerDetails}>
                    <div style={{ fontWeight: "bold" }}>Type</div>
                    <div>{data?.site_details?.site_type}</div>
                  </div>
                  <div className={pcs.containerDetails}>
                    <div style={{ fontWeight: "bold" }}>No.of Floors</div>
                    <div>{data?.site_details?.floors}</div>
                  </div>
                  <div className={pcs.containerDetails}>
                    <div style={{ fontWeight: "bold" }}>Area</div>
                    <div>{data?.site_details?.site_area}</div>
                  </div>
                </div>
                <div className={pcs.containerPlan1}>
                  <div className={pcs.containerDetailsPlan}>
                    <div style={{ fontWeight: "bold" }}>2D</div>
                    <div>
                      <div>{data?.site_details?.is_twoD ? "Yes" : "No"}</div>
                    </div>
                  </div>
                  <div className={pcs.containerDetailsPlan}>
                    <div style={{ fontWeight: "bold" }}>3D</div>
                    <div>
                      <div>{data?.site_details?.is_threeD ? "Yes" : "No"}</div>
                    </div>
                  </div>
                  <div className={pcs.containerDetailsPlan}>
                    <div style={{ fontWeight: "bold" }}>Estimation</div>
                    <div>
                      <div>
                        {data?.site_details?.is_budget_estimation
                          ? "Yes"
                          : "No"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={pcs.containerButton}>
                <Link to={`projects/${data?.job_id}`}>
                  <Button color={"color1"}>View</Button>
                </Link>
           
              </div>
            </div>
          </div>
        ))}
      </div>
    
    </div>
  );
}

export default ProjectCard;
