import React, { useContext, useEffect, useState } from "react";
import { Button, FormInput, ModalComponent } from "../../../../components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FileSelector from "./components/fileSelector/FileSelector";
import apis from "../../../../api";
import file from "../../../../assets/file.png";
import Delete from "../../../../assets/delete.svg";
import Download from "../../../../assets/download.svg";
import { BackIcon } from "../../../../assets";
import FileDownloader from "./components/FileDownloader/fileDownloader";
import FileUpload from "./fileuploadStyles.module.css";

import DeleteFileModal from "../DeleteFile/DeleteFileModal";
import { AuthContext } from "../../../../context/AuthContext/AuthContext";
const fu = FileUpload;
function FileUploadSection() {
  const navigate =useNavigate();

  const params = useParams();
  const { userInfo } = useContext(AuthContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDownload, setShowDownload] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [downloadData, setDownloadData] = useState({});
  const [projectFiles, setProjectFiles] = useState([]);
  const location = useLocation();
 const handleGoBack=()=>{
  navigate(-1)
 }
  const handleModal = () => {
    setShowModal(true);
  };
  const handelClose = () => {
    setShowModal(false);
    setShowDownload(false);
  };
  const handleAllFiles = async () => {
    const res = await apis.GetAllFiles(
      params?.jobId,
      location.state.project_key,
      location.state.section_key
    );
    console.log("-------------------->", res);
    setProjectFiles(res);
  };
  useEffect(() => {
    handleAllFiles();
  }, []);
  const handleFileDownload = async (data: any) => {
    console.log("data-------------", data);
    const res = await apis.GetDownloadUrl(data);
    console.log(res);
    const url = {
      ...res,
      name: data?.name + "." + data?.extension,
    };
    setDownloadData(url);
  };
  const handelDeleteClose = () => {
    setShowDelete(false);
  };

  console.log(projectFiles, "files>>>>>>>>>>>>>>>>>>>>>>");
  const [sectionKey, setSectionKey] = useState("");
  const [projectKey, setProjectKey] = useState("");
  const [userKey, setUserKey] = useState("");
  const [fileKey, setFileKey] = useState("");

  const OpenDeleteModal = (
    UserKey: any,
    projectKey: any,
    SectionKey: any,
    FileKey: any
  ) => {
    setUserKey(UserKey);
    setProjectKey(projectKey);
    setSectionKey(SectionKey);
    setFileKey(FileKey);
    setShowDelete(true);
  };

  return (
    <div>
      <div>
       <div onClick={handleGoBack } className={fu.text1}><img src={BackIcon} alt="backward-arrow" height={20} width={20}/><p>Back</p></div> <div className={fu.text}> Upload Section Files</div>
        <br></br>
        <hr />
        <div className={fu.button}>
          <Button color="color2" onClick={handleModal}>
            + Upload File
          </Button>
        </div>
        <div>
          {projectFiles &&
            projectFiles.map((data: any) => (
              <div className={fu.maincard}>
                <div className={fu.card}>
                  <div className={fu.fileName}>
                    <img className={fu.image} src={file} alt="" />
                    <p>{`${data.file_name}.${data.file_extension}`}</p>
                  </div>
                  <div className={fu.more}>
                    <div
                      onClick={() => {
                        handleFileDownload({
                          extension: data?.file_extension,
                          file_name: data?.file_key,
                          path: data?.file_path,
                          name: data?.file_name,
                        });
                        setShowDownload(true);
                        console.log(data);
                      }}
                    >
                      <img className={fu.image} src={Download} alt="" />
                    </div>
                    <div
                      onClick={() => {
                        console.log("User file>>>>>>>>>>", userInfo?.user_key);
                        console.log("Project file>>>>>>>", data);
                        console.log(
                          "Section file>>>>>>>>>>.",
                          data?.section_key
                        );
                        console.log("File file<>>>", data?.file_key);
                        OpenDeleteModal(
                          userInfo?.user_key,
                          data?.project_key,
                          data?.section_key,
                          data?.file_key
                        );
                      }}
                    >
                      <img className={fu.image} src={Delete} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <ModalComponent
        Component={() => (
          <FileSelector
            close={handelClose}
            project_key={location.state.project_key}
            section_key={location.state.section_key}
            getfunction={handleAllFiles}
          />
        )}
        isOpen={showModal}
        onClose={handelClose}
        title="File Selector"
      />
      <ModalComponent
        Component={() => (
          <FileDownloader close={handelClose} url={downloadData} />
        )}
        isOpen={showDownload}
        onClose={handelClose}
        title="File Download"
      />
      <ModalComponent
        Component={() => (
          <DeleteFileModal
            section={sectionKey}
            project={projectKey}
            file={fileKey}
            user={userKey}
            close={handelDeleteClose}
          />
        )}
        isOpen={showDelete}
        onClose={handelDeleteClose}
        title="File Delete"
      />
    </div>
  );
}

export default FileUploadSection;
