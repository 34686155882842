import React from "react";
import newProjectStyle from "./newprojectStyle.module.css";
import { NewProjectCard, ProjectTable } from "./Components";

const nps = newProjectStyle;


function NewProject({ newProjectList }: any) {
  return (
    <>
      <div className={nps.NewProjectSty}>
        <ProjectTable projectList={newProjectList} />
      </div>
      <div className={nps.NewProjectCardSty}>
        <NewProjectCard projectList={newProjectList} />
      </div>
    </>
  );
}

export default NewProject;
