import { z } from "zod";

const schema = z
  .object({
    email: z
      .string()
      .nonempty("Email is required")
      .email("Must be a valid email"),
    otp: z.string().min(6, "should six characters").nonempty(),
    password: z
      .string()
      .min(8, "Password must contain at least 8 character(s)")
      .nonempty(),
  })
  .required();

export default schema;
