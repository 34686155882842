import React from "react";
import { ProjectCard } from "../Components";
import completedStyle from "./completedStyle.module.css"

const cs = completedStyle;
function Completed({ completedProjectList }: any) {
  return (
    <>
      {completedProjectList.length === 0 ? <div className={cs.custom_message}>**Completed Projects Not Available**</div> : <><div className={cs.completedMain}>
        <div style={{ fontWeight: "bold" }}>Completed</div>
        <hr />
      </div>
        <div>
          <ProjectCard projectList={completedProjectList} />
        </div></>}
    </>
  );
}

export default Completed;
