import React, { useContext, useState } from "react";
import loginStyles from "./loginPage.module.css";
import { useAuthContext } from "../../../../../hooks";
import { useForm } from "react-hook-form";
import { Button, FormInput } from "../../../../../components";
import { zodResolver } from "@hookform/resolvers/zod";
import { CadMobileLogo } from "../../../../../assets/index";
import { z } from "zod";
import schema from "./shema";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../context/AuthContext/AuthContext";
const ls = loginStyles;
type Inputs = z.infer<typeof schema>;
function LoginPage() {
  const { signIn } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const auth = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    // reset,
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data: Inputs) => {
    const res = await signIn(data?.username, data?.password);
    console.log(res);
    if (res?.message?.code === "NotAuthorizedException") {
      alert("Incorrect Username or password");
      setIsLoading(false);
    } else if (res?.statusCode === 201) {
      navigate(`/createNewPassword`);
      setIsLoading(false);
    }
  };
  console.log(errors);
  return (
    <div className={ls.login_page}>
      <img src={CadMobileLogo} alt="logo"></img>
      {/* <h1 className={ls.title}>CAD CONSDROID</h1> */}
      <div className={ls.formpage}>
        <div>
          <h2>Cad Designer Login</h2>
        </div>
        <form className={ls.d}>
          <div className={ls.inputs}>
            <FormInput
              type="text"
              id="username"
              {...register("username")}
              className={ls.input}
              placeholder="Username"
              width="100"
            />
            {errors.username && (
              <p className={ls.errors} id="password-error">
                {errors.username?.message}
              </p>
            )}
            <br />

            <FormInput
              type="password"
              id="password"
              {...register("password")}
              className={ls.input}
              placeholder="Password"
              width="90"
            />
            {errors.password && (
              <p className={ls.errors} id="password-error">
                {errors.password?.message}
              </p>
            )}
            <br />

            <Link to="/forgotpassword" className={ls.forget}>
              Forget Password ?
            </Link>
            <br />
            {isLoading ? (
              <Button
                className={ls.login_button}
                onClick={handleSubmit(onSubmit)}
              >
                Login
              </Button>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>
      <div className={ls.dontaccount}>
        <p className={ls.donthaveac}>
          Don't have an account? <Link to="/signup">Register here</Link>
        </p>
      </div>
    </div>
  );
}

export default LoginPage;
