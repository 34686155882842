import { PATCH } from "../../../helpers/restFunction";

export const UpdateFileUpload = async (userId: any, jobId: any) => {
  const path = `/cad/users/${userId}/jobs/${jobId}`;
  const data = {
    file_upload_status: "uploaded",
  };
  return await PATCH({ apiUri: path, data })
    .then((response) => {
      return response[1];
    })
    .catch((err) => {
      console.log("Error in update");
      return err;
    });
};
