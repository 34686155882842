import React from "react";
import { HamburgerIcon } from "../../assets";
import Menu from "./components/menu";
import HamBurgerStyles from "./hamburger.module.css";
import LoginMenu from "./components/LoginMenu/loginMenu";
const hams = HamBurgerStyles;
interface IHamburger {
  toggled: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  size: number;
  color: string;
  onToggle?: String;
  isLogin?: boolean;
}

const Hamburger = ({
  toggled,
  setToggle,
  size,
  color,
  onToggle,
  isLogin,
}: IHamburger) => {
  const handleClick = () => {
    setToggle(!toggled);
  };

  return (
    <div className={hams.main_con}>
      <div onClick={handleClick}>
        {toggled ? (

          <Menu />

        ) : (
          <img src={HamburgerIcon} alt="HAMBURGERICON" />
        )}
      </div>
      {/* </button> */}
    </div>
  );
};

export default Hamburger;
