import { GET } from "../../../helpers/restFunction";

export const GetSiteFileData = async ({ file_name, path, extension }: any) => {
  const apipath = `/getUrl?file_name=${file_name}&path=${path}&extension=${extension}`;
  return await GET(apipath)
    .then((result) => {
      return result[1];
    })
    .catch((err) => {
      console.log("Error", err);
      return err;
    });
};
