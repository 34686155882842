import { POST } from "../../../helpers/restFunction";
export const CreateSignUp = async (data: Object) => {
  const path = `/cad/applications`;
  return await POST(path, data)
    .then((result: any[]) => {
      return result[1];
    })
    .catch((err: any) => {
      console.log("error on fetching objective >", err);
      return err;
    });
};
