import React from "react";
import AccountsDetailStyles from "./accountDataStyles.module.css";

const acs = AccountsDetailStyles;
interface IAccountDetails {
  Allpayments: never[];
}
function AccountsDataCard({ Allpayments }: IAccountDetails) {
  console.log(Allpayments)
  return (
    Allpayments &&
    (Object.keys(Allpayments).length !== 0 ? (
      <div className={acs.main_con}>
        <h1>
          <b>Transaction History</b>
        </h1>
        {Allpayments?.map(
          ({ job_id, site_key, job_status, paid_status, paid_amount }) => (
            <div className={acs.card}>
              <div className={acs.card_con}>
                <div className={acs.card_label}>Job Id :</div>
                <div className={acs.card_value}>{job_id}</div>
              </div>
              <div className={acs.card_con}>
                <div className={acs.card_label}>Site Key :</div>
                <div className={acs.card_value}>{site_key}</div>
              </div>
              <div className={acs.card_con}>
                <div className={acs.card_label}>Job Status :</div>
                <div className={acs.card_value}>{job_status}</div>
              </div>
              <div className={acs.card_con}>
                <div className={acs.card_label}>Action :</div>
                {paid_status ? (
                  <div
                    className={acs.card_value}
                    style={{ color: "rgba(2, 128, 2, 0.788)" }}
                  >
                    Completed
                  </div>
                ) : (
                  <div
                    className={acs.card_value}
                    style={{ color: "rgb(241, 76, 71)" }}
                  >
                    Pending
                  </div>
                )}
              </div>
              <div className={acs.card_con}>
                <div className={acs.card_label}>Salary Credited : </div>
                <div className={acs.card_value}>{paid_amount}</div>
              </div>
            </div>
          )
        )}
      </div>
    ) : (
      <div> you don't have any payment data</div>
    ))
  );
}

export default AccountsDataCard;
