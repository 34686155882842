import React from "react";
import { Button } from "../../../../components";
import DeleteFileStyle from "./DeleteFileStyle.module.css";
import apis from "../../../../api";

const dfs = DeleteFileStyle;

function DeleteFileModal({ user, project, section, file, close }: any) {
  console.log("User file", user);
  console.log("Project file", project);
  console.log("Section file", section);
  console.log("File file", file);

  const DeleteFile = async () => {
    const res = await apis.DeleteSectionFile(user, project, section, file);
    console.log(res, "??????????");
    if (res.message === "success") {
      alert("File Deleted");
      window.location.reload();
    } else {
      alert("Error");
    }
  };
  return (
    <div className={dfs.deleteSection}>
      <div>Are you sure you want to delete this section?</div>
      <div className={dfs.deleteSectionBtn}>
        <Button onClick={() => DeleteFile()}>Yes</Button>
        <Button onClick={() => close()}>No</Button>
      </div>
    </div>
  );
}

export default DeleteFileModal;
