import React, { useContext, useEffect, useState } from "react";
import { SupportImage } from "../../assets";
import Data_Validation from "./SupportData";
import apis from "../../api/index";
import { Button, FormInput } from "../../components";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import supportStyles from './supportStyle.module.css'
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import SupportDataCard from '../../pages/Support/components/SupportDataCard'
import { Loader } from "../../components/Loader/Loader";
const ss = supportStyles;
type Inputs = z.infer<typeof Data_Validation.schema>;
function Support() {
  const auth = useContext(AuthContext)
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Inputs>({
    resolver: zodResolver(Data_Validation.schema),
  });
  const [allTickets, SetAllTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const handleTickets = async () => {
    const res = await apis.GetAllTickets(auth?.user?.attributes?.sub);
    SetAllTickets(res);
    console.log(res);
  };
  useEffect(() => {
    handleTickets();
    if (allTickets) {
      setIsLoading(false)
    }
  }, []);
  const onSubmit = async (data: Inputs) => {
    const res = await apis.CreateTicket(auth?.user?.attributes?.sub, data)
    console.log(res)
    reset({ comments: "", reason_for_support: "" })
    handleTickets();
  };

  const handleChatData = ((id: number) => {
    navigate(`${id}`)
  })

  return (
    <div className={ss.main}>
      <div className={ss.top}>
        <div className={ss.left_reason}>
          <div className={ss.reason_support}>
            <h4 className="reason_support">Reason for Support</h4>
            <FormInput
              id="text-box"
              type="text"
              className="text text-box"
              placeholder="Reason"
              {...register("reason_for_support")}
            />
            {errors.reason_for_support && (
              <p className={ss.errors}>{errors.reason_for_support?.message}</p>
            )}
          </div>
          <div className={ss.comments}>
            <h4 className="comments">Comments</h4>
            <textarea
              className="text"
              placeholder="Comments"
              {...register("comments")}
            />
            {errors.comments && (
              <p className={ss.errors}>{errors.comments?.message}</p>
            )}
          </div>
          <div className={ss.button}>
            <Button
              onClick={handleSubmit(onSubmit)}
              className={ss.button_content}
            >
              {" "}
              Send
            </Button>

          </div>
          <div>
          </div>
        </div>
        <div className={ss.right_contactus}>
          <div className={ss.support_Image}>
            <img
              className={ss.support_supportimage}
              src={SupportImage}
              alt="supportimage"
            />
          </div>
          <div className={ss.contactus}>
            <div className={ss.contactustext}>
              <h3> Contact us</h3>
            </div>
            <div className={ss.mailcall}>
              {Data_Validation.SUPPORT_DATA?.map((data: any) => (
                <>
                  <img src={data?.icon} alt={data?.alt} />
                  <p className={ss.details_text}> {data?.Details}</p>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      {isLoading ? <Loader /> :
        <div className={ss.bottom}>
          <h3 className={ss.recenttickets}>Recent Tickets </h3>

          <table className={ss.table}>
            <thead>
              <tr>
                <th>T.No</th>
                <th>Reason for Support</th>
                <th>Comments</th>
                <th>Status</th>
                <th>Chat</th>
              </tr>
            </thead>
            <tbody>
              {allTickets.map(({ id, comment, reason_for_support, status }) => (
                <tr>
                  <td> {id}</td>
                  <td> {reason_for_support}</td>
                  <td> {comment}</td>
                  <td>{status}</td>
                  <td>
                    <Button color="color2" width="narrow" onClick={(() => { handleChatData(id) })}>View</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={ss.mobile_support_card}>

            <SupportDataCard Alltickets={allTickets} />
          </div>
        </div>}
    </div>
  );
}
export default Support;
