import { GET } from "../../../helpers/restFunction";

const GetAllFiles = async (
  jobId?: string,
  projectKey?: string,
  sectionKey?: string
) => {
  try {
    const path = `/admin/jobProject/${jobId}/jobProject/${projectKey}/section/${sectionKey}/files`;
    const response = await GET(path);
    return response[1];
  } catch (error) {
    console.log(error);
    return error;
  }
};
export default GetAllFiles;
