const REQUEST_PARAMS = {
    method: "GET",
    headers: {
        "X-CSCAPI-KEY": "c2tBVUxOOEJyNzhpQktNenFGMUswdWY1SzczMTRyWUJ4VWgyU0daTA==",
    },
};

export const getCountries = () => {
    return fetch("https://api.countrystatecity.in/v1/countries/", REQUEST_PARAMS)
        .then((_) => _.json())
        .then((response) => {
            // console.log(response);
            return response;
        });
};
export const getStatesByCountry = (countryIso2: any) => {
    return fetch(
        `https://api.countrystatecity.in/v1/countries/${countryIso2}/states`,
        REQUEST_PARAMS
    )
        .then((_) => _.json())
        .then((response) => {
            // console.log(response);
            return response;
        });
};
export const getCitiesByStateAndCountry = (countryIso2: any, stateIso2: any) => {
    return fetch(
        `https://api.countrystatecity.in/v1/countries/${countryIso2}/states/${stateIso2}/cities`,
        REQUEST_PARAMS
    )
        .then((_) => _.json())
        .then((response) => {
            // console.log(response);
            return response;
        });
};
