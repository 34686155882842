import { GET } from "../../../helpers/restFunction";
interface IFiles {
  file_name: string;
  path: string;
  extension: string;
}
const GetDownloadUrl = async (data: IFiles) => {
  try {
    const path = `/getUrl?file_name=${data?.file_name}&path=${data?.path}&extension=${data?.extension}`;
    const response = await GET(path);
    return response[1];
  } catch (error) {
    console.log(error);
    return error;
  }
};
export default GetDownloadUrl;
