import { GET } from "../../../helpers/restFunction";

export const GetOnGoing = async (userId: string) => {
  const path = `/cad/users/${userId}/jobs?job_status=ongoing`;
  return await GET(path)
    .then((result) => {
      return result[1];
    })
    .catch((err) => {
      console.log("Error", err);
      return err;
    });
};
