import React, { useContext } from 'react'
import setnewPasswordStyles from "./setnewpasswordStyles.module.css";
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Setschema from './Setschema';
import { AuthContext } from '../../../context/AuthContext/AuthContext';
import { useNavigate } from 'react-router-dom';

const ss = setnewPasswordStyles;
type Inputs = z.infer<typeof Setschema>

function SetNewPassword() {
  const navigate = useNavigate();
  const { forgotPassword } = useContext(AuthContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Inputs>({
    resolver: zodResolver(Setschema),
  });

  const onSubmit = async (data: Inputs) => {
    const res = await forgotPassword.setPassword(data?.email, data?.otp, data?.password);
    if (res?.statusCode === 200) {
      alert("Password set Successfully")
      navigate(`/`)
    }
  };

  return (
    <div className={ss.main}>
      <div className={ss.cadconsdroid}>
        <h1> CAD CONSDROID</h1>
      </div>
      <div className={ss.form}>
        <h1> Set Password</h1>
        <div className={ss.inputs}>
          <input type='email' className={ss.input} placeholder='Email'
            {...register("email")}>


          </input>
          {errors.email && (
            <p className={ss.errors} id="email-error">
              {errors.email?.message}
            </p>
          )}

          <input type='text' id='otp' className={ss.input} placeholder='OTP'
            {...register("otp")}>


          </input>
          {errors.otp && (
            <p className={ss.errors} id="otp-error">
              {errors.otp?.message}
            </p>
          )}

          <input type='password' className={ss.input} placeholder='Password'
            {...register("password")}  >

          </input>
          {errors.password && (
            <p className={ss.errors} id="password-error">
              {errors.password?.message}
            </p>
          )}
        </div>
        <button className={ss.button}
          onClick={handleSubmit(onSubmit)}> Reset Password</button>
      </div>
    </div>
  )
}

export default SetNewPassword