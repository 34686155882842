import React from "react";
import DownloadContentStyle from "./downloadContent.module.css";
import { useNavigate } from "react-router-dom";
interface DownloadProps {
  content: any;
  downloadUrl?: any
}
const dcs = DownloadContentStyle;
function DownloadContent({ content }: DownloadProps) {
  console.log("Downloading content", content);
  const handleDownload = async (imageurl: string, imageName: string) => {
    try {
      const response = await fetch(imageurl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = imageName;
      link.click();
      URL.revokeObjectURL(url);
      console.log("Url>>>>>>", url, link);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };
  return (
    <div>
      <div className={dcs.downloadConetentMain}>
        Are you sure you want to download
      </div>
      <hr />
      <div
        className={dcs.downloadConetentMain}
        onClick={() => {
          handleDownload(content?.downloadUrl, content?.name);
        }}
      >
        Download
      </div>
    </div>
  );
}

export default DownloadContent;
