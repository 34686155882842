import React, { useState, useEffect } from "react";
import {
  AuthContextProps,
  AuthProviderProps,
  forgotProps,
  signInFunctionProps,
} from "./type";
import { Auth } from "aws-amplify";
import { GET } from "../../helpers/restFunction";

export const AuthContext = React.createContext<AuthContextProps>(
  {} as AuthContextProps
);

export const AuthContextProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [idToken, setIdToken] = useState<any>();
  const [authParams, setAuthParams] = useState<any>({});
  const [userAttr, setUserAttr] = useState<any>(null);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [isSigned, setIsSigned] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getUserInfo = async (authKey: string) => {
    return await GET(`/cad/users/${authKey}`)
      .then((result: any) => {
        console.log(result[1], "Success");
        return result[1];
      })
      .catch((err: any) => {
        console.log("error on fetching user info > ", err);
        return err;
      });
  };

  async function fetchUser() {
    setIsAuthenticating(true);
    try {
      const session = await Auth.currentSession(); //returns tokens
      setIdToken(session?.getIdToken());
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      setUser(authenticatedUser);
      const userInfoRes = await getUserInfo(authenticatedUser?.attributes?.sub);
      console.log(userInfoRes?.first_name);
      setUserInfo(userInfoRes);
      // if (userInfoRes[0] === 200) {
      // } else {
      //   console.log("error occured. signing out...");
      //   await signOut();
      //   return;
      // }
      setIsAuthenticated(true);
      setUserAttr(authenticatedUser?.attributes);
      return authenticatedUser;
    } catch (error) {
      console.log("fetchUser >", error);
    }
  }
  useEffect(() => {
    //prevent logged in user from log out
    fetchUser().then((_) => setIsAuthenticating(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn: signInFunctionProps = async (username, password) => {
    if (!username) {
      alert("Please enter your username");
      return;
    }
    if (!password) {
      alert("Please enter your password");
      return;
    }
    setIsAuthenticating(true);
    const res = await Auth.signIn(username, password)
      .then(async (result: any) => {
        setUser(result);
        setUserAttr(result?.attributes);
        const userInfoRes = await getUserInfo(result?.attributes?.sub);
        console.log(userInfoRes?.first_name);
        setUserInfo(userInfoRes);
        setIdToken(result?.signInUserSession?.idToken?.jwtToken);
        console.log(result);

        if (result?.challengeName === "NEW_PASSWORD_REQUIRED") {
          setIsAuthenticating(false);
          return {
            statusCode: 201,
            message: result,
            redirectTo: "/createNewPassword",
          };
        }
        setIsAuthenticating(false);
        setIsAuthenticated(true);

        return { statusCode: 200, message: result, redirectTo: "/" };
      })
      .catch((err: any) => {
        setIsAuthenticating(false);
        if (err?.code === "UserNotConfirmedException") {
          return {
            statusCode: 403,
            message: err,
            redirectTo: `/verify?user=${username}`,
          };
        }
        return {
          statusCode: 400,
          message: { code: err.code, info: err.message },
          redirectTo: "/",
        };
      });
    return res;
  };

  const signOut = async () => {
    await Auth.signOut()
      .then((res: any) => {
        console.log("signout res", res);
        setIsAuthenticated(false);
        setUser(null);
      })
      .catch((err: any) => {
        console.log("err in signout", err);
      });
  };

  async function newPasswordChallenge(newPassword: string) {
    console.log("user new password challenge > ", user);
    if (user) {
      try {
        const response = await Auth.completeNewPassword(user, newPassword);
        console.log("newPasswordChallenge > ", response);
        return { statusCode: 200, response };
      } catch (error) {
        console.log("error newPasswordChallenge in", error);
        return { statusCode: 400, error };
      }
    } else {
      const error = "error in newPasswordChallenge > user not found ";
      console.log(error);
      return { statusCode: 400, error };
    }
  }
  const forgotPassword: any = {
    requestCode: async (username: any) => {
      setIsLoading(true);
      const res = await Auth.forgotPassword(username)
        .then((_) => {
          console.log(_);
        })
        .catch((err) => console.log("err", err));
      setIsLoading(false);
      console.log("code sent", username);
      return res;
    },
    setPassword: async (username: any, code: any, password: any) => {
      setIsLoading(true);
      const res = await Auth.forgotPasswordSubmit(username, code, password)
        .then((_) => {
          console.log(_);
        })
        .catch((err) => console.log("err", err));
      setIsLoading(false);
      console.log(username, code, password);
      return res;
    },
  };
  const value: AuthContextProps = {
    isAuthenticated,
    isAuthenticating,
    signIn,
    signOut,
    newPasswordChallenge,
    user,
    userInfo,
    forgotPassword,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
