import { API_URL } from "../../config";
interface UploadFilesUserDataProps {
  content_type: string;
  path: string;
  file_name?: string;
  extension: string;
}

export const uploadFiles = async (
  file: any,
  userData: UploadFilesUserDataProps
) => {
  const contentType = userData?.content_type;
  await fetch(`${API_URL}/uploads`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({
      ...userData,
    }),
  })
    .then((res) => res.json())
    .then((response) => {
      const bucketUrl = response.uploadURL;
      console.log("upload response Newly added > ", bucketUrl);
      const headers = new Headers();
      fetch(bucketUrl, {
        method: "PUT",
        body: file,
        headers: {
          ...headers,
          "Content-Type": contentType,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            console.log("form submitted successfully");
          } else {
            alert("Failed");
            console.log("error while uploading file");
          }
          console.log(`success ${res.status} - ${res.statusText}`);
        })
        .catch((err) => {
          console.log(err);
          console.log(`error on bucket url ${err.status} - ${err.statusText}`);
        });
    })
    .catch((err) => console.log("error while fetching api endpoint", err));
};
