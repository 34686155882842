import React, { useContext, useEffect } from "react";
import AccountCardtyles from "./accountsStyles.module.css";
import { Button } from "../../../../components";
import apis from "../../../../api";
import { AuthContext } from "../../../../context/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../../components/Loader/Loader";
const acs = AccountCardtyles;
function AccountsCard() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [accountDetails, setAccountDetails] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<any>(true);
  const handleAccountDetails = async () => {
    const res = await apis.GetBankDetails(auth?.user?.attributes?.sub);
    console.log(res);
    setAccountDetails(res);
    setIsLoading(false)
  };
  useEffect(() => {
    handleAccountDetails();
  }, []);
  // console.log(accountDetails);
  return (
    <div className={acs.main_class}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={acs.accounts_card}>
          {accountDetails &&
            (Object.keys(accountDetails).length !== 0 ? (
              <>
                <div className={acs.card_title}>Account Details</div>
                <div className={acs.card_content_div}>
                  <div className={acs.card_content}>
                    <div className={acs.content_title}>Account Holder Name</div>
                    <div className={acs.content}>
                      {accountDetails?.account_holder_name}
                    </div>
                  </div>
                  <div className={acs.card_content}>
                    <div className={acs.content_title}>Bank name</div>
                    <div className={acs.content}>
                      {accountDetails?.bank_name}
                    </div>
                  </div>
                  <div className={acs.card_content}>
                    <div className={acs.content_title}>Account Number</div>
                    <div className={acs.content}>
                      {accountDetails?.account_number}
                    </div>
                  </div>
                  <div className={acs.card_content}>
                    <div className={acs.content_title}>IFSC Code</div>
                    <div className={acs.content}>
                      {accountDetails?.ifsc_code}
                    </div>
                  </div>
                  <div className={acs.card_content}>
                    <div className={acs.content_title}>Contact</div>
                    <div className={acs.content}>
                      {accountDetails?.phone_number}
                    </div>
                  </div>
                  <div className={acs.card_content}>
                    <div className={acs.content_title}>UPI Id</div>
                    <div className={acs.content}>{accountDetails?.upi_id}</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div> You Dont' have an Account</div>
              </>
            ))}
          {accountDetails &&
            (Object.keys(accountDetails).length !== 0 ? (
              <>
                <br />
                <Button
                  color="color2"
                  onClick={() => {
                    navigate(`accountdetails`, { state: accountDetails });
                  }}
                >
                  Edit Account
                </Button>
                <br />
              </>
            ) : (
              <Button
                color="color2"
                onClick={() => {
                  navigate(`accountdetails`, { state: accountDetails });
                }}
              >
                Add Account
              </Button>
            ))}
        </div>
      )}
    </div>
  );
}

export default AccountsCard;
