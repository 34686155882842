import { PATCH } from "../../../helpers/restFunction";
export const UpdateBankDetails = async (
  userId: any,
  accountId: any,
  data: any
) => {
  const path = `/cad/${userId}/cad_bank_account/${accountId}`;
  return await PATCH({ apiUri: path, data })
    .then((result) => {
      return result[1];
    })
    .catch((err) => {
      console.log("error on fetching objective >", err);
      return err;
    });
};
