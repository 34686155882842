import { POST } from "../../../helpers/restFunction";
export const CreateBankDetails = async (userId: string, data: Object) => {
  const path = `/cad/${userId}/cad_bank_details`;
  return await POST(path, data)
    .then((result) => {
      return result[1];
    })
    .catch((err) => {
      console.log("error on fetching objective >", err);
      return err;
    });
};
