import React, { useContext, useState } from "react";
import Three_DStyles from "./threeDStyles.module.css";
import Folder from "../../../../assets/Folder.svg";
import Delete from "../../../../assets/delete.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalComponent } from "../../../../components";
import NewSection from "../UploadSection/UploadSection";
import DeleteModal from "../DeleteSection/DeleteModal";
import { AuthContext } from "../../../../context/AuthContext/AuthContext";

const tds = Three_DStyles;

const Three_D = (props: any) => {
  console.log(props);
  const { userInfo } = useContext(AuthContext);
  const location = useLocation();
  const { id, key } = location.state || {};
  console.log("ID:", id, location.state);
  console.log("Key:", key);
  const Navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  //Here the section key is passed as props through navigation, so that you access the section in the fileUpload component.

  console.log("props here", props);
  const handleClick = (section_key: any) => {
    Navigate(`section`, {
      state: { section_key: section_key, project_key: key },
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const [sectionKey, setSectionKey] = useState("");
  const [projectKey, setProjectKey] = useState("");
  const [userKey, setUserKey] = useState("");

  const OpenDeleteModal = (SectionKey: any, projectKey: any, UserKey: any) => {
    setSectionKey(SectionKey);
    setProjectKey(projectKey);
    setUserKey(UserKey);
    setShowDeleteModal(true);
  };

  return (
    <>
      <div>
        <div className={tds.create_sec}>
          <div className={tds.create_text} onClick={() => setShowModal(true)}>
            New Section
          </div>
        </div>

        <div className={tds.card2}>
          {props.Three_dDesign?.map((data: any) => {
            return (
              <>
                <div className={tds.card}>
                  <div className={tds.card1}>
                    <div
                      onClick={() => handleClick(data?.section_key)}
                      className={tds.tempFolder}
                    >
                      <div className={tds.foldername}>
                        <img src={Folder} alt="" />
                      </div>
                      <div className={tds.foldername}>{data?.section_name}</div>
                    </div>
                    <div
                      onClick={() => {
                        OpenDeleteModal(
                          data?.section_key,
                          data?.project_key,
                          userInfo?.user_key
                        );
                      }}
                    >
                      <img src={Delete} alt="" />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>

        {/* <div className={tds.card}>
          {props.Three_dDesign?.map((data: any) => {
            console.log(data?.section_name, data?.section_key, data);
            return (
              <p
                className={tds.foldername}
                onClick={() => handleClick(data?.section_key)}
              >
                <span>
                  <img src={Folder} alt="" />
                </span>
                {data?.section_name}
              </p>
            );
          })}
        </div> */}
        <ModalComponent
          Component={() => (
            <NewSection
              design_template="3d"
              close={handleClose}
              project_key={key}
              getfunction={props?.getfunction}
            />
          )}
          isOpen={showModal}
          onClose={handleClose}
          title="Create new section"
        />
        <ModalComponent
          Component={() => (
            <DeleteModal
              section={sectionKey}
              project={projectKey}
              user={userKey}
            />
          )}
          isOpen={showDeleteModal}
          onClose={handleDeleteClose}
          title="Delete section"
        />
      </div>
    </>
  );
};

export default Three_D;
