import React, { useContext, useEffect, useState } from "react";
import apis from "../../api";
import AccountStyles from "./accountStyles.module.css";
import AccountsCard from "./components/AccountsCard/AccountsCard";
import AccountsDetails from "./components/AccountsDetails/AccountsTable/AccountsDetails";
import AccountsDataCard from "./components/AccountsDetails/AccountsDataCard/AccountsDataCard";
import { AuthContext } from "../../context/AuthContext/AuthContext";
const as = AccountStyles;

function Accounts() {
  const [allPayments, setAllPayments] = React.useState([]);
  const auth = useContext(AuthContext);

  const handlePaymentData = async () => {
    const res = await apis.GetJobStatus(auth?.user?.attributes?.sub);
    console.log(res);
    setAllPayments(res);
  };
  useEffect(() => {
    handlePaymentData();
  }, []);

  return (
    <>
      <div className={as.main_con}>
        <div className={as.account_card}>
          <AccountsCard />
        </div>
        <div className={as.account_table}>
          <AccountsDetails Allpayments={allPayments} />
        </div>
        <div className={as.account_data_card}>
          <AccountsDataCard Allpayments={allPayments} />
        </div>
      </div>
      /
    </>
  );
}

export default Accounts;
