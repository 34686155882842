import React, { useContext, useEffect } from "react";
import AccountsDetailStyles from "./accountsStyles.module.css";
const ads = AccountsDetailStyles;
interface IAccountDetails {
  Allpayments: never[];
}
function AccountsDetails({ Allpayments }: IAccountDetails) {
  return (
    <div>
      {Allpayments &&
        (Object.keys(Allpayments).length !== 0 ? (
          <div className={ads.acccount_table}>
            <table className={ads.table}>
              <thead>
                <tr>
                  <th>Job Id</th>
                  <th>Site Key</th>
                  <th>Job Status</th>
                  <th>Action</th>
                  <th>Salary Credited</th>
                </tr>
              </thead>
              <tbody>
                {Allpayments?.map(
                  ({
                    job_id,
                    site_key,
                    job_status,
                    paid_status,
                    paid_amount,
                  }) => (
                    <tr>
                      <td> {job_id}</td>
                      <td> {site_key}</td>
                      <td>{job_status}</td>

                      {!paid_status ? (
                        <td className={ads.pending}>Pending</td>
                      ) : (
                        <td className={ads.completed}>Completed</td>
                      )}

                      <td>{paid_amount}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div> you don't have any payment data</div>
        ))}
    </div>
  );
}

export default AccountsDetails;
