import React, { useState, useEffect, useContext } from "react";
import Button from "./../../components/Buttons/Button";
import JobsButtonStyles from "./jobStyles.module.css";
import Three_D from "./components/3D/Three_D";
import Two_D from "./components/2D/Two_D";
import apis from "../../api";
import { useLocation } from "react-router-dom";
import Estimation from "./components/Estimation/Estimation";
import { Loader } from "../../components/Loader/Loader";
const jbs = JobsButtonStyles;

function Jobs() {
  const [ButtonName, setButtonName] = useState("3D");

  //This two state hold the data from the backend
  const [two_DData, setTwo_DData] = useState([]);
  const [three_DData, setThree_DData] = useState([]);
  const [estimationData, setEstimationData] = useState([]);
  const [isLoading, setIsloading] = useState(true)
  //To get the value from the Accepted project that is passed via the navigate
  //state: { id: job_id, key: project_folder_key }

  const location = useLocation();
  const { id, key } = location.state || {};
  console.log("ID:", id, location.state);
  console.log("Key:", key);
  const [selectedButton, setSelectedButton] = useState("3D")
  const handleClick = (buttonName: string) => {
    setButtonName(buttonName);
    setSelectedButton(buttonName)
  };

  //To fetch data for Three-d design
  const fetch_Three_D_data = async () => {
    try {
      const three = await apis.GetAllThree_DDesign(id, key);
      console.log(three);
      setThree_DData(three);
    } catch (error) { }
  };
  useEffect(() => {
    fetch_Three_D_data();
    fetch_Two_D_data();
    fetch_data_estimation();
    if (three_DData) { setIsloading(false) }
  }, []);

  //To fetch data for Two-d design

  const fetch_Two_D_data = async () => {
    try {
      const two = await apis.GetAllTwo_DDesign(id, key);
      console.log(two);
      setTwo_DData(two);
    } catch (error) { }
  };

  //To fetch data for Estimation

  const fetch_data_estimation = async () => {
    try {
      const estimation = await apis.GetAllEstimation(id, key);
      setEstimationData(estimation);
      console.log(estimation);
    } catch (error) { }
  };

  return (
    <div>
      <div className={jbs.button}>
        <Button color={selectedButton === "3D" ? "color1" : "color3"} width="narrow" onClick={() => handleClick("3D")}>
          3D
        </Button>
        <Button color={selectedButton === "2D" ? "color1" : "color3"} width="narrow" onClick={() => handleClick("2D")}>
          2D
        </Button>
        <Button
          color={selectedButton === "Estimation" ? "color1" : "color3"}
          width="narrow"
          onClick={() => handleClick("Estimation")}
        >
          Estimation
        </Button>
      </div>

      <div>
        {isLoading ? <Loader /> : ButtonName === "3D" && (
          <Three_D
            Three_dDesign={three_DData}
            getfunction={fetch_Three_D_data}
          />
        )}
        {ButtonName === "2D" && <Two_D Two_dDesign={two_DData} getfunction={fetch_Two_D_data} />}
        {ButtonName === "Estimation" && (
          <Estimation estimation_data={estimationData} getfunction={fetch_data_estimation} />
        )}
      </div>
    </div>
  );
}

export default Jobs;
