import { POST } from "../../../helpers/restFunction";
export const CreateJobSection = async (data: Object) => {
  const path = `/admin/jobSections`;
  return await POST(path, data)
    .then((result) => {
      return result[1];
    })
    .catch((err) => {
      console.log("error on fetching objective >", err);
      return err;
    });
};
