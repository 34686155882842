import { z } from "zod";
const schema = z.object({
  account_holder_name: z.string().min(2, "*Enter the Name*").nonempty(),
  account_number: z.string().min(8, "required").nonempty(),
  bank_name: z.string().min(8, "required").nonempty(),
  ifsc_code: z.string().min(8, "required").nonempty(),
  phone_number: z
    .string()
    .min(10, "*atleast 10 character requires")
    .max(10, "exceeeds max length")
    .nonempty(),
  upi_id: z.string().min(10, "reqiured").nonempty(),
});

export default schema;
