import { POST } from "../../../helpers/restFunction";
export const CreateSupport = async (
  userId: String,
  support_id: number,
  data: Object
) => {
  const path = `/cad/${userId}/cad_support_comment/${support_id}`;
  return await POST(path, data)
    .then((result) => {
      return result[1];
    })
    .catch((err) => {
      console.log("error on fetching objective >", err);
      return err;
    });
};
