import { z } from "zod";

const schema = z
  .object({
    username: z.string().email("Invalid username").nonempty(),
    password: z
      .string()
      .min(8, "Password must contain at least 8 character(s)")
      .nonempty(),
  })
  .required();

export default schema;
