import React from "react";
import { ProjectCard } from "../Components";
import onGoingStyle from "./onGoingStyle.module.css"

const ogs = onGoingStyle;
interface ISiteDetails {
  site_name: string;
  budget_max: number;
  budget_min: number;
  build_area: string;
  floors: number;
  is_budget_estimation: boolean;
  is_threeD: boolean;
  is_twoD: boolean;
  site_area: number;
  site_breadth: number;
  site_key: string;
  site_length: number;
  site_purpose: string;
  site_type: string;
  siteplan_price_with_gst: number;
  ec_fileextension: string;
  ec_filename: string;
  ec_filepath: string;
  ec_filetype: string;
  ec_key: string;
  ec_survey_number: string;

}
interface IProjectData {
  job_id: string;
  site_area: number;
  site_details: ISiteDetails;
}

interface OnGoingProjectProps {
  onGoingProjectList: IProjectData[];
}

function OnGoingProject({ onGoingProjectList }: OnGoingProjectProps) {
  console.log(onGoingProjectList)
  return (
    <>
      {onGoingProjectList.length === 0 ? <div className={ogs.custom_message}>**No Ongoing Projects Available here**</div> : <><div className={ogs.onGoingMain}>
        <div style={{ fontWeight: "bold" }}>Ongoing Projects</div>
        <hr />
      </div>
        <div>
          <ProjectCard projectList={onGoingProjectList} />
        </div></>}
    </>
  );
}

export default OnGoingProject;
