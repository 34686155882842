import { GET } from "../../../helpers/restFunction";

export const CheckExistingMail = async (email_id: string) => {
  const path = `/cad/email/${email_id}`;
  return await GET(path)
    .then((response) => {
      return response[1];
    })
    .catch((err: any) => {
      console.log("Error", err);
    });
};
