import React, { useContext, useEffect, useState } from "react";
import projectDetailStyle from "./projectDetailStyle.module.css";
import apis from "../../../../api";
import { useNavigate, useParams } from "react-router-dom";
import SiteDetails from "./ProdetailComponent/SiteDetails";
import FloorDetails from "./ProdetailComponent/FloorDetails";
import { AuthContext } from "../../../../context/AuthContext/AuthContext";

const pds = projectDetailStyle;
function ProjectDetail() {
  const auth = useContext(AuthContext)
  const navigate = useNavigate();
  let { jobId } = useParams();
  const [projectDetails, setProjectdetails] = useState<any>();
  const handleProjectdetails = async (jobId?: string) => {
    const res = await apis.GetProjectdetails(auth?.user?.attributes?.sub, jobId);
    console.log("job", jobId);
    setProjectdetails(res);
    console.log(res);
  };

  const [jobDetails, setJobDetails] = useState<any>();
  const handleJobdetails = async (jobId?: string) => {
    const res = await apis.GetProjectdetails(auth?.user?.attributes?.sub, jobId);
    console.log("job", jobId);
    setJobDetails(res);
    console.log(res);
  };

  useEffect(() => {
    handleProjectdetails(jobId);
    handleJobdetails(jobId);
  }, []);

  const handleAcceptPlan = async () => {
    const tempData = {
      ...jobDetails,
      job_status: "ongoing",
    };
    const res = await apis.UpdateSiteAccept(jobId, tempData);
    navigate("/")
    console.log("Accept data", res);
  };

  const handleDeclinePlan = async () => {
    const tempData = {
      ...jobDetails,
      job_status: "rejected",
    };
    const res = await apis.UpdateSiteAccept(jobId, tempData);
    navigate("/")
    console.log("Accept data", res);
  };
  return (
    <>
      <div className={pds.pdContainer}>
        <div className={pds.projectDetailsContainer}>
          <div>
            <div className={pds.siteDetailContent}>
              <div className={pds.siteDetailsSection}>
                {projectDetails?.job_status == "pending" ? (
                  <>
                    <div className={pds.planAccDelete}>
                      <div
                        className={pds.accContainer}
                        onClick={handleAcceptPlan}
                      >
                        Accept
                      </div>
                      <div
                        className={pds.accContainer}
                        onClick={handleDeclinePlan}
                      >
                        Decline
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <SiteDetails siteDetails={projectDetails} />
                <FloorDetails siteDetails={projectDetails} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProjectDetail;
