import React, { ButtonHTMLAttributes, ReactNode } from "react";
import { Link } from "react-router-dom";
import ButtonStyles from "./buttonsStyle.module.css";

type ButtonColor = "color1" | "color2" | "color3" | "color4";
type ButtonWidth = "narrow" | "wide";
type ButtonIconPosition = "left" | "right";

interface ButtonProps
  extends Omit<
    ButtonHTMLAttributes<HTMLButtonElement>,
    "color" | "width" | "onCopy"
  > {
  text?: string;

  icon?: string;

  iconPosition?: ButtonIconPosition;

  navigateTo?: string;

  openModal?: boolean;

  color?: ButtonColor;

  width?: ButtonWidth;

  onClick?: () => void;

  children?: ReactNode;

  onModalOpen?: () => void;

  onModalClose?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  text,
  icon,
  iconPosition,
  navigateTo,
  openModal,
  color,
  width,
  onClick,
  children,
  ...rest
}) => {
  const bn = ButtonStyles;
  let buttonContent = (
    <>
      {icon && iconPosition === "left" && (
        <span className={bn.icon}>
          <img src={icon} alt="" />
        </span>
      )}
      <span className={bn.text}>{children}</span>
      {icon && iconPosition === "right" && (
        <span className={bn.icon}>
          <img src={icon} alt="" />
        </span>
      )}
    </>
  );

  if (navigateTo) {
    buttonContent = (
      <Link to={navigateTo} className={bn.button}>
        {buttonContent}
      </Link>
    );
  }

  return (
    <button
      className={`${bn.buttonWrapper} ${bn[color as keyof typeof bn]} ${bn[width as keyof typeof bn]
        }`}
      onClick={onClick}
      {...rest}
    >
      {buttonContent}
    </button>
  );
};

export default Button;
