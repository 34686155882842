import { GET } from "../../../helpers/restFunction";

const GetAllThree_DDesign = async (jobId: any, projectKey: number) => {
  try {
    const path = `/admin/jobProject/${jobId}/jobProject/${projectKey}?design_template=3d`;
    const response = await GET(path);
    return response[1];
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default GetAllThree_DDesign;
