import React, { useEffect, useState } from "react";
import projectComponentStyle from "./projectComponentStyle.module.css";
import { DownloadContent, ModalComponent } from "../../../../../components";
import apis from "../../../../../api";

const pcs = projectComponentStyle;

function SiteDocument({ siteDetails }: any) {
  
  const [downloadData, setDownloadData] = useState<any>([]);
  const [showDownload, setShowDownload] = useState<boolean>(false);

  const handleClose = () => {
    setShowDownload(false);
  };
  const handleFileDownload = async (data: any) => {
    console.log("data===================", data);
    const { file_name, path, extension } = data;
    const res = await apis.GetSiteFileData({ file_name, path, extension });
    console.log("handleFileDownload", res);
    const url = {
      ...res,
      name: data?.file_name.split(".").shift() + "." + data?.extension,
    };
    console.log("url--------------", url);
    setDownloadData(url);
  };
  return (
    <>
      <div className={pcs.SiteDocumentMain}>
        <div className={pcs.siteMainTitle}>
          <div className={pcs.siteTitle1}>Site Documents</div>
        </div>
        {siteDetails?.ec_survey_number ? (
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>EC Survey Number</div>
            <div> {siteDetails?.site_details?.ec_survey_number}</div>
          </div>
        ) : (
          <>
            <div className={pcs.siteContentDetails}>
              <div style={{ fontWeight: "bold" }}>EC Document</div>
              <div>{siteDetails?.site_details?.ec_filename}</div>
            </div>
            <div className={pcs.siteContentDetails}>
              <div
                className={pcs.siteContentDetailsBtn}
                style={{ fontWeight: "bold" }}
                onClick={() => {
                  handleFileDownload({
                    extension: siteDetails?.site_details?.ec_fileextension,
                    file_name: siteDetails?.site_details?.ec_key
                      .split(".")
                      .shift(),
                    path: siteDetails?.site_details?.ec_filepath,
                  });
                  setShowDownload(true);
                  console.log(">>>>>>>>>>>>>>>>>>>>>.data...", siteDetails);
                }}
              >
                Download
              </div>
            </div>
          </>
        )}
        <div className={pcs.siteContentDetails}>
          <div style={{ fontWeight: "bold" }}>Registration Document</div>
          <div>{siteDetails?.site_details?.registration_filename}</div>
        </div>
        <div className={pcs.siteContentDetails}>
          <div
            className={pcs.siteContentDetailsBtn}
            style={{ fontWeight: "bold" }}
            onClick={() => {
              handleFileDownload({
                extension:
                  siteDetails?.site_details?.registration_fileextension,
                file_name: siteDetails?.site_details?.registration_key
                  .split(".")
                  .shift(),
                path: siteDetails?.site_details?.registration_filepath,
              });
              setShowDownload(true);
              console.log(">>>>>>>>>>>>>>>>>>>>>.data...", siteDetails);
            }}
          >
            Download
          </div>
        </div>
        <ModalComponent
          Component={() => <DownloadContent content={downloadData} />}
          isOpen={showDownload}
          onClose={handleClose}
        />
      </div>
    </>
  );
}

export default SiteDocument;
