import React from "react";
import aboutStyles from "./aboutUs.module.css";

const as = aboutStyles;

function AboutUs() {
  return (
    <div className={as.main}>
      <div className={as.title}>
        <h1 className={as.animate_character}>Join our team and get paid</h1>
      </div>
      <div className={as.side_content}>
        <div className={as.abouttitle}>
          <h2 className={as.title_content}>About Cad Consdroid</h2>
          <div className={as.aboutus_text}>
            <p className={as.inner_content}>
              CAD consdroid is a platform for CAD designers
            </p>
            <p className={as.inner_content}>
              Designers can work on assigned projects and submit them for
              approval to receive payment.
            </p>
            <p className={as.inner_content}>
              Designers can upload their own templates to showcase their talents
              and earn money.
            </p>
          </div>
        </div>

        <div className={as.abouttitle}>
          <h2 className={as.title_content}>Project Management</h2>
          <p className={as.inner_content}>
            CAD consdroid manages projects and provides space to handle project
            details.
          </p>
        </div>
        <div className={as.abouttitle}>
          <h2 className={as.title_content}>Communication</h2>
          <p className={as.inner_content}>
            CAD consdroid manages projects and provides space to handle project
            details.
          </p>
        </div>
      </div>

    </div>
  );
}

export default AboutUs;
