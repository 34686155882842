import React, { useContext } from "react";
import uploadStyle from "./ProjectCardStyle.module.css"
import { Link } from "react-router-dom";
import apis from "../../../../api";
import { AuthContext } from "../../../../context/AuthContext/AuthContext";

const usty = uploadStyle

function UploadModal({ fileUploadStatus, close }: any) {
  console.log("FileuoploadStatus",fileUploadStatus)
  const auth = useContext(AuthContext)
  const uploadAllFiles = (async () => {
    const res = await apis.UpdateFileUpload(auth?.user?.attributes?.sub, fileUploadStatus?.job_id)
    console.log(res)
    if (res?.message === "success") {
      alert("File Submitted To Admin")
      close();
      window.location.reload();
    } else {
      alert("something went wrong")
      close();
      window.location.reload();
    }
  })
  return (
    <>
      <div>
        {fileUploadStatus?.file_upload_status == "pending" ? (
          <div className={usty.UploadCard}>
            <div className={usty.templateUpload}>
              Did you upload all file in Template Page regarding this siteplan
              project?
            </div>
            <div className={usty.uploadButton}>
              <div className={usty.yesButton} onClick={
                uploadAllFiles
              }>Yes</div>
              <div className={usty.yesButton}>No</div>
            </div>
            <div className={usty.templateUpload}>
              if you want to upload files in {" "}<div>{"   "}<Link to={"/template"}> Template?</Link></div>
            </div>
          </div>
        ) : (
          <>
            <h6 className="fw-bold text-success">
              Files is Under Review by admin
            </h6>
          </>
        )}
      </div>
    </>
  );
}

export default UploadModal;
