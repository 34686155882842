import React, { useState } from "react";
import LoginStyles from "./loginStyles.module.css";
// import { IndexBg } from "../../../assets";
import AboutUs from "./components/AboutUs/aboutUs";
import WhyUs from "./components/WhyUs/whyUs";
import Loginform from "./components/LoginForm/loginPage";

const ls = LoginStyles;

function Login() {
  const [activeTab, setActiveTab] = useState("about");
  const [toggle, setToggle] = useState(false);
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };
  return (
    <div className={ls.main}>
      <div className={ls.left_content}>
        <div className={ls.leftContent_overlay}>
          <div className={ls.tabs}>
            <div
              className={`tab ${activeTab === "about" ? "active" : ""}`}
              onClick={() => handleTabClick("about")}
            >
              <h4 className={ls.about}>About Us</h4>
            </div>

          </div>
          <div className="content">
            {activeTab === "about" && (
              <div className="about-content">
                <AboutUs />
              </div>
            )}

          </div>
        </div>
      </div>

      <div className={ls.right_content}>
        <div className={ls.right_cadform}>
          <Loginform />
        </div>
      </div>


    </div>
  );
}

export default Login;
