import React from "react";
import newProjectCard from "./newProjectCardStyle.module.css";
import { Button } from "../../../../../components";
import { SitePlan } from "../../../../../assets";
import { Link } from "react-router-dom";

const npcs = newProjectCard;

interface ISiteDetails {
  site_name: string;
  budget_max: number;
  budget_min: number;
  build_area: string;
  floors: number;
  is_budget_estimation: boolean;
  is_threeD: boolean;
  is_twoD: boolean;
  site_area: number;
  site_breadth: number;
  site_key: string;
  site_length: number;
  site_purpose: string;
  site_type: string;
  siteplan_price_with_gst: number;
  ec_fileextension: string;
  ec_filename: string;
  ec_filepath: string;
  ec_filetype: string;
  ec_key: string;
  ec_survey_number: string;
}
interface INewProjectData {
  job_id: string;
  site_area: number;
  site_details: ISiteDetails;
}

function NewProjectCard({ projectList }: any) {
  return (
    <>
      {projectList.length === 0 ? <div className={npcs.custom_message}>
        **No New Projects Available**
      </div> :
        <div className={npcs.cardMain}>
          <div className={npcs.projectCardMain}>
            <div style={{ fontWeight: "bold" }}>New Project</div>
            <hr />
            {projectList.map((data: INewProjectData, index: number) => (
              <div key={index}>
                <div className={npcs.projectCardContainer}>
                  <div>
                    <div className={npcs.containerDetails}>
                      <div style={{ fontWeight: "bold" }}>JOB ID</div>
                      <div>{data?.job_id}</div>
                    </div>
                    <div className={npcs.containerDetails}>
                      <div style={{ fontWeight: "bold" }}>Area</div>
                      <div>{data?.site_details?.site_area}</div>
                    </div>
                  </div>
                  <div>
                    <div className={npcs.containerDetails}>
                      <div style={{ fontWeight: "bold" }}>Site Name</div>
                      <div>{data?.site_details?.site_name}</div>
                    </div>
                    <div className={npcs.containerDetails}>
                      <Link to={`projects/${data?.job_id}`}>
                        <Button color={"color1"}>View</Button>
                      </Link>
                    </div>
                  </div>
                  <div>
                    <img src={SitePlan} alt="siteImage" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>}
    </>
  );
}

export default NewProjectCard;
