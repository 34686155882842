import React, { useContext } from "react";
import AccountFormStyles from "./accountFormStyles.module.css";
import { Button, FormInput } from "../../../../components";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import schema from "./AccountSchma";
import { z } from "zod";
import { useLocation, useNavigate } from "react-router-dom";
import apis from "../../../../api";
import { AuthContext } from "../../../../context/AuthContext/AuthContext";
const afs = AccountFormStyles;
type Inputs = z.infer<typeof schema>;
function AccountsForm() {
  const navigate =useNavigate()
  const history = useLocation();
  const auth = useContext(AuthContext);
  const data: any = history.state;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
    defaultValues: {
      account_holder_name:
        data && Object.keys(data).length !== 0
          ? `${data?.account_holder_name}`
          : "",
      phone_number:
        data && Object.keys(data).length !== 0 ? `${data?.phone_number}` : "",
      ifsc_code:
        data && Object.keys(data).length !== 0 ? `${data?.ifsc_code}` : "",
      bank_name:
        data && Object.keys(data).length !== 0 ? `${data?.bank_name}` : "",
      account_number:
        data && Object.keys(data).length !== 0 ? `${data?.account_number}` : "",
      upi_id: data && Object.keys(data).length !== 0 ? `${data?.upi_id}` : "",
    },
  });
  const OnUpdate = async (e: any) => {
    const res = await apis.UpdateBankDetails(
      auth?.user?.attributes?.sub,
      data?.id,
      e
    );
    if (res?.message === "success") {
      alert("Updated Successfully");
      window.location.reload();
       window.history.back();
    }
  };
  const OnSubmit = async (e: any) => {
    const res = await apis.CreateBankDetails(auth?.user?.attributes?.sub, e);
    if (res?.message === "success") {
      alert("Account Added Successfully");
      window.location.reload();
      window.history.back();
    }
  };
  return (
    <div className={afs.main}>
      <div className={afs.main_con}>
        <div className={afs.card}>
          <div className={afs.card_title}>
            <h3>
              <b>Account Details</b>
            </h3>
          </div>
          <form
            onSubmit={handleSubmit(
              data && Object.keys(data).length !== 0 ? OnUpdate : OnSubmit
            )}
          >
            <div className={afs.card_content}>
              <label className={afs.label_text}>Account Holder Name</label>
              <div className={afs.card_input}>
                <FormInput
                  width="90"
                  backgroundColor="#D9D9D9"
                  {...register("account_holder_name")}
                />
                {errors && (
                  <p className={afs.errors}>
                    {errors?.account_holder_name?.message}
                  </p>
                )}
              </div>
            </div>
            <div className={afs.card_content}>
              <label className={afs.label_text}>Bank Name</label>
              <div className={afs.card_input}>
                <FormInput
                  width="90"
                  backgroundColor="#D9D9D9"
                  {...register("bank_name")}
                />
                {errors && (
                  <p className={afs.errors}>{errors?.bank_name?.message}</p>
                )}
              </div>
            </div>
            <div className={afs.card_content}>
              <label className={afs.label_text}>Account Number</label>
              <div className={afs.card_input}>
                <FormInput
                  width="90"
                  backgroundColor="#D9D9D9"
                  {...register("account_number")}
                />
                {errors && (
                  <p className={afs.errors}>
                    {errors?.account_number?.message}
                  </p>
                )}
              </div>
            </div>
            <div className={afs.card_content}>
              <label className={afs.label_text}>Ifsc Code</label>
              <div className={afs.card_input}>
                <FormInput
                  width="90"
                  backgroundColor="#D9D9D9"
                  {...register("ifsc_code")}
                />
                {errors && (
                  <p className={afs.errors}>{errors?.ifsc_code?.message}</p>
                )}
              </div>
            </div>
            <div className={afs.card_content}>
              <label className={afs.label_text}>Contact</label>
              <div className={afs.card_input}>
                <FormInput
                  width="90"
                  backgroundColor="#D9D9D9"
                  {...register("phone_number")}
                />
                {errors && (
                  <p className={afs.errors}>{errors?.phone_number?.message}</p>
                )}
              </div>
            </div>
            <div className={afs.card_content}>
              <label className={afs.label_text}>Upi Id</label>
              <div className={afs.card_input}>
                <FormInput
                  width="90"
                  backgroundColor="#D9D9D9"
                  {...register("upi_id")}
                />
                {errors && (
                  <p className={afs.errors}>{errors?.upi_id?.message}</p>
                )}
              </div>
            </div>
            <div className={afs.button}>
              {data && Object.keys(data).length !== 0 ? (
                <Button
                  color="color2"
                  onClick={() => {
                    handleSubmit(OnUpdate);
                  }}
                >
                  UPDATE
                </Button>
              ) : (
                <Button
                  color="color2"
                  onClick={() => {
                    handleSubmit(OnSubmit);
                  }}
                >
                  ADD ACCOUNT
                </Button>
              )}
            </div>
            &nbsp;
          </form>
        </div>
      </div>
    </div>
  );
}

export default AccountsForm;
