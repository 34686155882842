import { GET } from "../../../helpers/restFunction";

const GetSupportDetails = async (
  userId: String,
  supportId?: String | Number
) => {
  const path = `/cad/${userId}/cad_support_details/${supportId}`;
  return await GET(path)
    .then((result) => {
      return result[1];
    })
    .catch((err) => {
      console.log("error on fetching objective >", err);
      return err;
    });
};
export default GetSupportDetails;
