import React from "react";
import ModalStyle from "./modalStyles.module.css";
import { CloseButton } from "../../assets";

type ButtonColor = "color1" | "color2" | "color3" | "color4";
type ButtonIconPosition = "left" | "right";

interface ModalContentProps {
  closeModal?: () => void;
}
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  icon?: string;
  Component?: React.ComponentType<ModalContentProps>;
  img?: string;
  buttonText?: string;
  color?: ButtonColor;
  iconPosition?: ButtonIconPosition;
  title?: string;
}

const ModalComponent: React.FC<ModalProps & ModalContentProps> = ({
  isOpen,
  onClose,
  icon,
  Component,
  img,
  buttonText,
  color,
  iconPosition,
  title
}) => {
  const ms = ModalStyle;
  console.log(isOpen);
  const handleCloseModal = () => {
    onClose();
    console.log("modal clicked from modal component");
  };

  return (
    <>
      {isOpen && (
        <div className={`${ms.modal} ${isOpen ? ms.open : ""}`}>

          <div className={`${ms.modal_content} ${isOpen ? ms.open : ""}`}>
            <div className={ms.modal_title}>
              <div className={ms.modal_heading}><b> {title}</b></div>
              <div className={ms.close_button} onClick={() => handleCloseModal()}>
                <img src={CloseButton} alt="Close" />
              </div>
            </div>
            <div className={ms.component}>
              {Component && <Component closeModal={handleCloseModal} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalComponent;
