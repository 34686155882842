import React from "react";
import { Routes, Route } from "react-router-dom";
import AppLayout from "../Layouts/appLayout/appLayout";
import { Accounts, Projects, Support, Template } from "../pages";
import Signup from "../pages/Auth/Signup/Signup";
import ForgotPassword from "../pages/Auth/ForgotPassword/ForgotPassword";
import Preview from "../pages/Auth/Signup/Preview/Preview";
import SetNewPassword from "../pages/Auth/SetNewPassword/SetNewPassword";
import Ticket from "../pages/Tickets/Ticket";
import Jobs from "../pages/Jobs/Jobs";
import FileUploadSection from "../pages/Jobs/components/FileUploadSection/FileUploadSection";
import ProjectDetail from "../pages/Projects/Components/ProjectDetail/ProjectDetail";
import SignupSetNewPassword from "../pages/Auth/Signup/SetNewPassword/SetNewPassword"
import AccountsForm from "../pages/Accounts/components/AccountsForm/AccountsForm";;

const Router = () => {
  return (
    <Routes>
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/createNewPassword" element={< SignupSetNewPassword />} />
      <Route path="/setNewPassword" element={<SetNewPassword />} />
      <Route path="Preview" element={<Preview />} />
      <Route path="/" element={<AppLayout />}>
        <Route index element={<Projects />} />
        <Route path="projects/:jobId" element={<ProjectDetail />} />
        <Route path="accounts">
          <Route index element={<Accounts />} />
          <Route path="accountdetails" element={<AccountsForm />} />
        </Route>
        <Route path="support" >
          <Route index element={<Support />} />
          <Route path=":supportId" element={<Ticket />} />
        </Route>
        <Route path="template">
          <Route index element={<Template />} />
          <Route path=":jobId">
            <Route index element={<Jobs />} />
            <Route path="section" element={<FileUploadSection />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default Router;
