import React from "react";
import AcceptedProjectStyles from "./acceptedprojectStyles.module.css";
import builldingLine from "../../../../assets/building_line.svg";
import { useNavigate } from "react-router-dom";
import { Button, ModalComponent } from "../../../../components";
import UploadModal from "../../../Projects/Components/ProjectCard/UploadModal";
const aps = AcceptedProjectStyles;

const Acceptedprojects = (props: any) => {
  const navigate = useNavigate();
  const [fileData,setFileData]=React.useState({})
  //From Parent => (OngoingJobs) is passed as props here it contains the value of job_id and project_key and the handlefunction navigates to <Job />
 const [isOpen,setIsopen]=React.useState<boolean>(false);
  const handlefunction = (job_id: any, project_folder_key: any) => {
    console.log(job_id, project_folder_key);
    navigate(`${job_id}`, {
      state: { id: job_id, key: project_folder_key },
    });
  };
  const handleClose =()=>{
    setIsopen(!isOpen)
  }
  const handleUploadJobFiles=(data:any)=>{
   setFileData(data);
   setIsopen(true)
  }
  return (
    <>
      <div className={aps.con}>
        <div>
          <table className={aps.table}>
            <tr>
              <th>S.no</th>
              <th>Project Folder</th>
              <th>Action</th>
            </tr>
            {props.OngoingJobs?.map((ongoing: any, id = 0) => (
              <tr key={id}>
                <td>{++id}</td>
                <td
                  onClick={() => {
                    handlefunction(
                      ongoing?.job_id,
                      ongoing?.project_folder_key
                    );
                  }}
                >
                  {ongoing.job_id}
                </td>
                <td><div className={aps.button_view}> <Button color="color1" width="narrow" onClick={()=>(handleUploadJobFiles(ongoing))}>Submit</Button></div></td>
              </tr>
                
            ))}
            {console.log(props.OngoingJobs)}
          </table>
        </div>
        <div>
          <img className={aps.image} src={builldingLine} alt="" />
        </div>
      </div>
      <ModalComponent
                  Component={() => (
                    <UploadModal
                      fileUploadStatus={fileData}
                      close={handleClose}
                    />
                  )}
                  isOpen={isOpen}
                  onClose={handleClose}
                />
    </>
  );
};

export default Acceptedprojects;
