import { GET } from "../../../helpers/restFunction";

export const GetJobDetails = async (jobId?: string) => {
  const path = `/admin/jobTable/${jobId}`;
  return await GET(path)
    .then((result) => {
      return result[1];
    })
    .catch((err) => {
      console.log("Error", err);
      return err;
    });
};
