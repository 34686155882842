import React from "react";
import projectComponentStyle from "./projectComponentStyle.module.css";
import SiteDocument from "./SiteDocument";

const pcs = projectComponentStyle;
function SiteDetails({ siteDetails }: any) {
  return (
    <>
      <div className={pcs.sitePlan}>
        <div>
          <div className={pcs.sitePlanTitle}>
            <div className={pcs.siteTitle}>Site Details</div>
            <div className={pcs.siteStatus}>{siteDetails?.job_status}</div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>Site Name</div>
            <div>{siteDetails?.site_details?.site_name}</div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>Site Type</div>
            <div>{siteDetails?.site_details?.site_type}</div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>Area</div>
            <div>{siteDetails?.site_details?.site_area + " " + "Sq.Ft"}</div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>Length</div>
            <div>{siteDetails?.site_details?.site_length + " " + "Sq.Ft"}</div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>Breadth</div>
            <div>{siteDetails?.site_details?.site_breadth + " " + "Sq.Ft"}</div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>Budget</div>
            <div>
              Rs.{siteDetails?.site_details?.budget_max}
              {" - "}Rs.{siteDetails?.site_details?.budget_min}
            </div>
          </div>
        </div>
        <div>
          <div className={pcs.siteTitle}>Site Area Requirements</div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>Outer Area</div>
            <div>
              {siteDetails?.site_details?.outer_gap_area + " " + "Sq.Ft"}
            </div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>Front Area</div>
            <div>{siteDetails?.site_details?.front_area + " " + "Sq.Ft"}</div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>Back Area</div>
            <div>{siteDetails?.site_details?.back_area + " " + "Sq.Ft"}</div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>Floor Count</div>
            <div>{siteDetails?.site_details?.floors}</div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>Parking</div>
            <div>
              {siteDetails?.site_details?.is_parking == "1" ? "Yes" : "No"}
            </div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>Garden</div>
            <div>
              {siteDetails?.site_details?.is_garden == "1" ? "Yes" : "No"}
            </div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>Terrace</div>
            <div>
              {siteDetails?.site_details?.is_terrace == "1" ? "Yes" : "No"}
            </div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>2D</div>
            <div>
              {siteDetails?.site_details?.is_twoD == "1" ? "Yes" : "No"}
            </div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>3D</div>
            <div>
              {siteDetails?.site_details?.is_threeD == "1" ? "Yes" : "No"}
            </div>
          </div>
          <div className={pcs.siteContentDetails}>
            <div style={{ fontWeight: "bold" }}>Estimation</div>
            <div>
              {siteDetails?.site_details?.is_budget_estimation == "1"
                ? "Yes"
                : "No"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SiteDetails;
