import { GetJobDetails } from "./Project/GET/GetJobDetails.api";
import { UpdateSiteAccept } from "./Project/Update/UpdatePlanAccept.api";
import { GetSiteFileData } from "./Project/GET/GetSiteFile.api";
import { GetOnGoing } from "./Project/GET/GetOnGoing.api";
import GetAllTickets from "./Support/GET/GetAllTickets.api";
import { CreateTicket } from "./Support/POST/CreateTicket.api";
import { GetnewProject } from "./Project/GET/GetNewProject.api";
import { GetCompletedProject } from "./Project/GET/GetCompletedProject.api";
import { CreateSupport } from "./Support/POST/CreateSupport.api";
import GetSupportDetails from "./Support/GET/GetSupportDetails.api";
import GetBankDetails from "./Accounts/GET/GetBankDetails.api";
import { CreateBankDetails } from "./Accounts/POST/CreateBankDetails.api";
import { UpdateBankDetails } from "./Accounts/PATCH/UpdateBankDetails.api";
import GetAllOngoingJobs from "./Template/GET/GetAllOngoingJobs.api";
import GetJobStatus from "./Accounts/GET/GetJobStatus.api";
import { CreateChat } from "./Tickets/POST/CreateChat.api";
import GetChat from "./Tickets/GET/GetChat.api";
import { CreateJobSection } from "./Template/POST/CreateJobSection.api";
import GetAllThree_DDesign from "./Three_D/GET/GetAllThree_DDesigns.api";
import GetAllTwo_DDesign from "./Two_D/GET/GetAllTwo_DDesigns.api";
import GetAllEstimation from "./Estimation/GET/GetAllEstimation.api";
import { GetProjectdetails } from "./Project/GET/GetProjectDetails.api";
import { CreateJobFileUpload } from "./Template/POST/CreateFileUpload.api";
import GetAllFiles from "./Template/GET/GetAllUploadedFiles";
import GetDownloadUrl from "./Template/File/getFile.api";
import { UpdateFileUpload } from "./Project/Update/UpdateFileUpload.api";
import { CreateSignUp } from "./SignUp/Post/CreateSignUp.api";
import DeleteTemplateSection from "./Template/Delete/DeleteSection.api";
import DeleteSectionFile from "./Template/Delete/DeleteFile.api";
import {CheckExistingMail} from "./SignUp/Get/GetExistingMail.api"
const apis = {
  GetAllTickets,
  CreateTicket,
  GetnewProject,
  GetOnGoing,
  GetCompletedProject,
  GetSupportDetails,
  CreateSupport,
  GetBankDetails,
  CreateBankDetails,
  UpdateBankDetails,
  GetAllOngoingJobs,
  GetProjectdetails,
  GetJobStatus,
  CreateChat,
  GetChat,
  GetAllThree_DDesign,
  GetAllTwo_DDesign,
  GetAllEstimation,
  CreateJobSection,
  GetSiteFileData,
  GetJobDetails,
  UpdateSiteAccept,
  CreateJobFileUpload,
  GetAllFiles,
  GetDownloadUrl,
  UpdateFileUpload,
  CreateSignUp,
  DeleteTemplateSection,
  DeleteSectionFile,
  CheckExistingMail,
};
export default apis;
