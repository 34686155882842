import { GET } from "../../../helpers/restFunction";

const GetAllOngoingJobs = async (userId: String) => {
  try {
    const path = `/cad/adminAssignedProjects/${userId}?job_status=ongoing`;
    const response=await GET(path)
    return response[1]
  } catch (error) {
    console.log(error)
    return error
  }
};
export default GetAllOngoingJobs;
