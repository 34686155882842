import React, { useContext, useState } from 'react'
import { Button, FormInput } from '../../../../../../components'
import { GenerateUniqueKey } from '../../../UploadSection/UploadSection';
import { AuthContext } from '../../../../../../context/AuthContext/AuthContext';
import { uploadFiles } from '../../../../../../api/FileUpload/fileupload';
import apis from '../../../../../../api';
interface IuploadFiles {
    section_key?: string | number;
    close: () => void;
    project_key?: string | number
    getfunction: () => void;
}
let fileListArray = [];

function FileSelector(props: IuploadFiles) {
    const auth = useContext(AuthContext)
    const [uploadedFiles, setUploadedFiles] = useState<any>([]);
    const handleChange = ((e: any) => {
        console.log(e)
        fileListArray = Array.from(e);
        setUploadedFiles(fileListArray)
        console.log(uploadedFiles)
    })
    const handleSubmit = (async () => {

        console.log(uploadedFiles, props)
        const fileKey = GenerateUniqueKey();
        const dataPost = {
            section_key: props?.section_key,
            file_name: uploadedFiles[0].name.split(".")[0],
            file_type: uploadedFiles[0].type,
            file_extension: uploadedFiles[0].name.split(".").pop(),
            file_key: fileKey,
            file_path: `Cad-Design-Templates/${auth?.user?.attributes?.sub}/${props?.project_key}/${props?.section_key}/`,

        };
        console.log(dataPost)
        const s3BucketUploadData = {
            file_name: dataPost.file_key,
            content_type: dataPost.file_type,
            path: `Cad-Design-Templates/${auth?.user?.attributes?.sub}/${props?.project_key}/${props?.section_key}/`,
            extension: dataPost.file_extension,
        };
        console.log(s3BucketUploadData)
        await uploadFiles(uploadedFiles[0], s3BucketUploadData).then(async () => {
            const res = await apis.CreateJobFileUpload(dataPost)
            console.log(res)
            if (res.message === "success") {
                alert("File Uploaded Successfully")
                props.close();
                props.getfunction();
            }
            else {
                alert("something went wrong")
                props.close();
            }
        })
    })
    return (
        <div>
            <div>
                Select a File
            </div>
            <br />
            <FormInput type="file"
                width="100"
                id="formFile"
                name="workFile"

                onChange={(_) => {
                    handleChange(_.target.files)

                }} />
            &nbsp;
            <Button width="narrow" color='color2' onClick={handleSubmit}>Submit</Button>
        </div>
    )
}

export default FileSelector