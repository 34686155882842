import React from 'react'
import { Button, FormInput } from '../../../../components'
import { useForm } from 'react-hook-form';
import { schema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import apis from '../../../../api';
import UploadStyles from "./uploadSectionStyles.module.css";
const us = UploadStyles;
type Inputs = z.infer<typeof schema>;
interface ICreatesections {
  design_template?: string
  close: () => void;
  project_key?: string | number
  getfunction: () => void;
}
export const GenerateUniqueKey = () => {
  const date = new Date();
  const components = [
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  ];
  // setUniquekey(components.join(""));
  const uniquekey = components.join("");

  return uniquekey;
};
function NewSection(props: ICreatesections) {
  console.log("new section>>>>>>", props);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
  });
  const onsubmit = async (data: any) => {
    const Upload_Data = {
      project_key: props?.project_key,
      section_name: data?.section_name,
      section_key: GenerateUniqueKey(),
      design_template: props?.design_template,
    };
    console.log("sssss", Upload_Data);
    const res = await apis.CreateJobSection(Upload_Data);
    console.log(res);
    props?.getfunction();
    props?.close();
  };
  return (
    <div
      className={us.uploads}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100",
        justifyContent: "space-around",
      }}
    >
      {/* <div className={us.section_title}>Create Section</div> */}
      <form className={us.form}>
        <div>
          <FormInput width="90" {...register("section_name")} />
          {errors && <p>{errors.section_name?.message}</p>}
        </div>
        <div className={us.buttons}>
          <Button
            className={us.buttons}
            onClick={handleSubmit(onsubmit)}
            color="color2"
          >
            Create Section
          </Button>
        </div>
      </form>
    </div>
  );
}

export default NewSection