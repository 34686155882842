import { DELETE } from "../../../helpers/restFunction";

const DeleteSectionFile = async (
  user_key: any,
  project_key: any,
  section_key: any,
  file_key: any
) => {
  try {
    const path = `/cad/users/${user_key}/projects/${project_key}/sections/${section_key}/files/${file_key}`;
    const response = await DELETE(path);
    return response[1];
  } catch (error) {
    return error;
  }
};

export default DeleteSectionFile;
