import { useAuthContext } from "../../hooks";
import LoginPage from "../../pages/Auth/Login/Login";
import { Loader } from "../../components/Loader/Loader";



const LoginLayout = () => {
  const { isAuthenticating } = useAuthContext();

  return !isAuthenticating ? <LoginPage /> : <Loader />;
};

export default LoginLayout;
