import React, { useContext, useEffect, useState } from "react";
import apis from "../../api";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import Acceptedprojects from "./components/Acceptedprojects/Acceptedprojects";
import TemplateStyle from "./templateStyles.module.css";
import { Loader } from "../../components/Loader/Loader";
const ts = TemplateStyle;

function Templates(props: any) {
  const auth = useContext(AuthContext);
  const [OngoingJobs, setOngoingJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  const fetchdata = async () => {
    try {
      const ongoing = await apis.GetAllOngoingJobs(auth?.user?.attributes?.sub);
      console.log(ongoing);
      setOngoingJobs(ongoing);
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.log("Error fetching ongoing jobs:", error);
    }
  };
  useEffect(() => {
    fetchdata().then(
      () => { setIsLoading(false) }
    )
  }, []);
  console.log(OngoingJobs)
  return (
    <> {isLoading ? <Loader /> : OngoingJobs.length === 0 ?
      <div className={ts.custom_message}>No Projects Accepted yet</div> :
      <div className={ts.template}>
        <h1 className={ts.title}>Accepted projects</h1>

        <Acceptedprojects OngoingJobs={OngoingJobs} />

      </div>}
    </>
  );
}

export default Templates;
