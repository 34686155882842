import React from "react";
import projectComponentStyle from "./projectComponentStyle.module.css";
import SiteDocument from "./SiteDocument";

const pcs = projectComponentStyle;
function FloorDetails({ siteDetails }: any) {
  return (
    <div>
      <div className={pcs.siteMainTitle}>
        <div className={pcs.siteTitle1}>Floor details</div>
      </div>
      <div className={pcs.sitePlanFloorDetails}>
        {siteDetails?.floorDetails
          ?.sort((a: any, b: any) => a.floor_number - b.floor_number)
          ?.map((data: any) => {
            return (
              <div>
                <div className={pcs.siteTitle}>
                  {data?.floor_number == 0
                    ? "Ground Floor Details"
                    : `Floor -${data.floor_number} Details`}
                </div>
                <div className={pcs.siteContentDetails}>
                  <div style={{ fontWeight: "bold" }}>Floor Description</div>
                  <div>{data?.floor_description}</div>
                </div>
                <div className={pcs.siteContentDetails}>
                  <div style={{ fontWeight: "bold" }}>Floor Type</div>
                  <div>{data?.floor_hallType}</div>
                </div>
                <div className={pcs.siteContentDetails}>
                  <div style={{ fontWeight: "bold" }}>Balcony</div>
                  <div>{data?.is_floor_balcony == 1 ? "Yes" : "No"}</div>
                </div>
                <div className={pcs.siteContentDetails}>
                  <div style={{ fontWeight: "bold" }}>Bedroom Count</div>
                  <div>{data?.floor_bedroom_count}</div>
                </div>
                {data?.bedRoomDetails.map((data: any) => {
                  return (
                    <div className={pcs.siteContentDetailsCardMain}>
                      <div className={pcs.siteContentDetailsCard}>
                        <div className={pcs.siteContentDetails}>
                          <div style={{ fontWeight: "bold" }}>Bedroom Type</div>
                          <div>{data.bedroom_type + " " + "Room"}</div>
                        </div>
                        <div className={pcs.siteContentDetails}>
                          <div style={{ fontWeight: "bold" }}>Bathroom</div>
                          <div>{data.is_bathroom == 1 ? "Yes" : "No"}</div>
                        </div>
                        <div className={pcs.siteContentDetails}>
                          {data?.is_bathroom == 1 ? (
                            <>
                              <div style={{ fontWeight: "bold" }}>
                                Bathroom Type
                              </div>
                              <div>{data?.bathroom_type}</div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className={pcs.siteContentDetails}>
                  <div style={{ fontWeight: "bold" }}>Kitchen</div>
                  <div>{data?.is_kitchen == 1 ? "Yes" : "No"}</div>
                </div>
                {data?.is_kitchen ? (
                  <div className={pcs.siteContentDetails}>
                    <div style={{ fontWeight: "bold" }}>Kitchen Type</div>
                    <div>{data?.kitchen_type}</div>
                  </div>
                ) : (
                  <></>
                )}

                <div className={pcs.siteContentDetails}>
                  <div style={{ fontWeight: "bold" }}>Bathroom Count</div>
                  <div>{data?.floor_bathroom_count}</div>
                </div>
              </div>
            );
          })}
      </div>
      <SiteDocument siteDetails={siteDetails} />
    </div>
  );
}

export default FloorDetails;
