import React from "react";
import { Link, useLocation } from "react-router-dom";
import SideBarIconsStyle from "./sidebarLinkStyle.module.css";
interface SidebarModuleProps {
  module: string;
  path: string;
  icon: string;

  activeModule: string | null;
  handleModuleClick: (module: string) => void;
}

const ss = SideBarIconsStyle;

const SidebarModule: React.FC<SidebarModuleProps> = ({
  module,
  path,
  icon,

  handleModuleClick,
}) => {
  const handleClick = () => {
    handleModuleClick(module);
  };

  const location = useLocation();

  return (
    <div>
      <Link
        to={path}
        className={`${ss.links} ${location.pathname === path ? ss.active : ""}`}
        onClick={handleClick}
      >
        <div className={ss.links_icon_name}>
          {location.pathname === path ? (
            <img src={icon} alt={module} />
          ) : (
            <img src={icon} alt={module} />
          )}
          <div className={ss.module_name}>{module}</div>
        </div>
      </Link>
    </div>
  );
};
export default SidebarModule;
