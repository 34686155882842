import { PATCH } from "../../../helpers/restFunction";

export const UpdateSiteAccept = async (jobId: any, data: any) => {
  const path = `/admin/jobTable/${jobId}`;
  return await PATCH({ apiUri: path, data })
    .then((response) => {
      return response[1];
    })
    .catch((err) => {
      console.log("Error in update");
      return err;
    });
};
