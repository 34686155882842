import React from "react";
import { Sidebar } from "../../components/index";
import { Outlet } from "react-router";
import { useAuthContext } from "../../hooks";
import LoginLayout from "../loginLayout/loginLayout";
import appLayoutStyles from "./appLayout.module.css";
import Hamburger from "../../components/HamburgerMenu/Hamburger";

const aps = appLayoutStyles;

const AppLayout = () => {
  const { isAuthenticated } = useAuthContext();
  const [toggled, setToggle] = React.useState(false);
  return isAuthenticated ? (
    <div className={aps.app_container}>
      <div className={aps.sidebar}>
        <Sidebar />
      </div>
      <div className={aps.hamburger}>

        <div className={aps.icon}><Hamburger toggled={toggled} setToggle={setToggle} size={20} color="red" /></div>
      </div>
      <div className={aps.outlet}>
        <Outlet />
      </div>
    </div>
  ) : (
    <LoginLayout />
  );
};

export default AppLayout;
