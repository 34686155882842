import React from "react";
import { Button } from "../../../../components";
import DeleteStyle from "./DeleteStyle.module.css";
import apis from "../../../../api";

const ds = DeleteStyle;

function DeleteModal({ section, user, project }: any) {
  const DeleteSection = async () => {
    const res = await apis.DeleteTemplateSection(user, project, section);
    if (res.message === "success") {
      alert("Section Deleted Successfully");
      window.location.reload();
    } else {
      alert("Error");
    }
  };
  return (
    <div className={ds.deleteSection}>
      <div>Are you sure you want to delete this section?</div>
      <div className={ds.deleteSectionBtn}>
        <Button
          onClick={() => {
            DeleteSection();
          }}
        >
          Yes
        </Button>
        <Button>No</Button>
      </div>
    </div>
  );
}

export default DeleteModal;
